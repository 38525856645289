.songOpinion span {
  white-space: break-spaces;
}
.songOpinion .doNotPlay {
  color: red;
}
.songOpinion .neutral {
  color: blue;
}
.songOpinion .meh {
  color: #FF8181;
}
.songOpinion .nothingExiting {
  color: blue;
}
.songOpinion .safeBet {
  color: purple;
}
.songOpinion .pettyGood {
  color: #53bd87;
}
.songOpinion .fuckingAwesome {
  color: #219820;
}
