@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";
@import (reference) "../../../../less/general";
.feedItem {
	display: flex;
	padding: 13px 6px 13px 0;
	position: relative;
	border-bottom: 1px solid @lightGray;

	.dropperImg{
		width: 50px; height: 50px;
		border-radius: 50%;
		margin-right: 12px;
	}
	.feedContent{
		width: 100%;

		header{
			.dropperName{
				font-weight: bold;
				.mediumText;
			}
			.separator{
				margin: 0 5px;
			}
			.addedAt{
				color: @grayText;
				.smallText;
			}

			.actionButtons{
				> a {
					float: right;
					color: lightgray;
					margin-left: 10px;

					&:hover{
						color: @grayText;
					}
				}
			}

			.redroppedLabel{
				color: @color2;
				.smallText;
			}
		}
		.dropContent{
			margin-top: 10px;
			display: inline-block;
			a{
				color: @color0_dark;
			}
		}
		.attachment{
			margin-top: 14px;
			justify-content: left;
			display: flex;
			img{
				border-radius: 20px;
			}

			.feedDropItemImage{
				img {
					max-width: 350px;
					max-height: 350px;
				}
			}
		}
		.originalFeedDrop{
			padding: 10px;
			.border;
			border-radius: @borderRadius;
			margin-top: 12px;
			display: flex;
		}
		footer{
			margin-top: 15px;
			.dropAction{
				text-decoration: none;
				cursor: pointer;
				i{
					color: black;
					margin-right: 20px;
					.mediumText;
				}
				&.disabled{
					color: lightgrey;
					i{
						color: lightgrey;
					}
				}
				&:hover i{
					transition-duration: .3s;
					transition-property: text-shadow;
					text-shadow: 2px 1px 0px @color2;
				}
				.amount{
					margin-right: 6px;
					.smallText;
				}
			}
		}
	}

	.blocker {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}