#feedReDropPopup {
  width: 550px;
}
#feedReDropPopup .modal-body {
  padding: 0;
}
#feedReDropPopup .modal-body .modal-header {
  margin-bottom: 0;
}
#feedReDropPopup .modal-body .modal-header .btn-close {
  font-size: 1.2rem;
  margin: 10px 0;
}
#feedReDropPopup .modal-body #dropArea {
  border: none;
}
#feedReDropPopup .modal-body .feedItem {
  padding: 20px;
  border: none;
}
