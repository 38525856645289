@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#changeUserCountryPopup {
  input{
    border: @border;
    width: 100%;
    padding: 10px;
  }
  .countries {
    margin-top: 10px;
    text-align: left;
    max-height: 350px;
    .country {
      padding: 6px 0;
      .flag {
        margin-right: 5px;
      }
      &:hover{
        background-color: @bgColor_hover;
      }
    }
  }
  #selectedCountry{
    margin-top: 10px;
    .mediumText;
    .flag-icon{
      margin-right: 4px;
    }
    button{
      margin-top: 10px;
    }
  }
}