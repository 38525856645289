@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../less/general';
@import (reference) '../../../less/variables';
@import (reference) '../../../less/animations';

#mobileFooter{
  //background:linear-gradient(90deg, rgba(0,0,0,0) 35%,  @glassBg_darker 100%);
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: flex-end;
  padding-right: 2px;
  background-color: white;
  border: 2px solid lightgrey;
  button{
    border-radius: 50% 50% 0;
    position: relative;
    bottom: 2px;
    height: 60px;
    width: 60px;
    font-size: 25px;
    .flex;
    text-shadow: 2px 2px 0px @color2;
    box-shadow: 4px 2px 0px @color2;
    &.createRoomBtn{
      font-size: 50px;
    }
    span{
      transform: rotate(0deg);
      transition: transform 0.15s;
    }
    &.songSearchDisplayed{
      background-color: @grayText;
      text-shadow: 2px 2px 0px @gray;
      box-shadow: 2px 2px 0px @gray;
      span{
        transform: rotate(45deg);
        left: 4px;
        top: 1px;
        position: relative;
      }
    }
  }
  //i{
  //  color: white;
  //  .bigText;
  //  margin-right: 30px;
  //  background-color: @glassBg_darker;
  //  border-radius: 50%;
  //  padding: 10px;
  //}

  #homePageMobileButtons {
    display: flex;
    align-items: center;
    > * {
      margin: 8px;
      .smallText;
      font-weight: bold;
    }
    .active {
      font-weight: 200;
      text-decoration: underline;
      .mediumText;
    }
  }

  #roomFooterActions{
    > * {
      font-size: 30px;
      font-size: 25px;
      margin: 15px;
      display: inline;
      .fa-sign-out-alt{
        .rotate-180;
      }
    }
  }

  #roomChatOpener {
    i {
      &.opened {
        color: @color0;
      }

      &.closed {
        color: @textColor;
      }

      &.unread {
          .blinkColor(@textColor, @color2, 2s);
      }
    }
  }
}