@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../less/variables";

#cookiesApproval{
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 1002;
    width: 100%;
    text-align: center;
    .mediumText;
    font-weight: bold;
    padding: 10px;
    border-top: @color2 3px solid;
    button{
        font-weight: lighter;
    }
    a {
        color: blue;
        margin-right: 5px;
    }
}
