#mySubscriptionModal .modal-body {
  padding: 0 27px 18px;
}
#mySubscriptionModal .modal-body h4 {
  font-weight: bold;
}
#mySubscriptionModal .modal-body ul {
  font-size: 1.2rem;
}
#mySubscriptionModal .modal-body .transferMsg {
  color: blue;
}
#mySubscriptionModal .modal-body .existsSubscriptionNotification {
  color: #219820;
  text-align: center;
  margin: 0 auto;
}
#mySubscriptionModal .modal-body #subscriptionFeatures {
  padding: 0;
  margin-top: 20px;
}
#mySubscriptionModal .modal-body #subscriptionFeatures li {
  font-size: 1.2rem;
  margin-bottom: 3px;
}
#mySubscriptionModal .modal-body #subscriptionFeatures li i {
  font-weight: bold;
  margin-right: 8px;
}
#mySubscriptionModal .modal-body #subscriptions {
  margin-top: 25px;
}
#mySubscriptionModal .modal-body #subscriptions .subscription {
  font-size: 1.2rem;
}
#mySubscriptionModal .modal-body #subscriptions .subscription td {
  padding: 0 8px 14px 0;
  font-weight: bold;
}
#mySubscriptionModal .modal-body #subscriptions .subscription td.packageName {
  font-weight: normal;
}
#mySubscriptionModal .modal-body #subscriptions .subscription td.packageName b {
  font-weight: bold;
}
#mySubscriptionModal .modal-body #subscriptions .subscription td.subscriptionPrice {
  font-size: 1rem;
}
#mySubscriptionModal .modal-body #subscriptions .subscription td.subscriptionPrice .subscriptionTxt {
  font-weight: normal;
  color: #219820;
}
#mySubscriptionModal .modal-body #subscriptions .subscription button {
  border: 0 none;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 2px;
  padding: 3px 9px 6px;
  margin-left: 6px;
  background-color: #55DA96;
  color: white;
  white-space: nowrap;
}
#mySubscriptionModal .modal-body #autorenew {
  text-align: center;
}
#mySubscriptionModal .modal-body #autorenew b {
  font-weight: 100;
}
#mySubscriptionModal .modal-body .loader {
  font-size: 3.5em;
  margin: 0 auto;
  display: block;
  text-align: center;
}
