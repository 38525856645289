@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/general";
@import (reference) "../../../../less/variables";

#ambassadorProgramPopup{
    .modal-body{
        .flex;
        white-space: inherit;
        flex-direction: column;
        text-align: center;
        p{
            .normalText;
        }
        #ambassadorLink{
            width: 100%;
            .copyPasteInput {
                position: relative;
                margin: 15px 10px 6px;
            }
        }
        #shareOptions{
            width: 285px;
        }
        #tip{
            .smallText;
            margin-bottom: 5px;
            i{
                color: #c1b368;
                margin-right: 5px;
            }
        }
        #thatSimple{
            margin: 15px 0;
            color: @color0;
            text-decoration: underline;
        }
        #thatSimpleParagraph{
            width: 100%;
            white-space: break-spaces;
            text-align: center;
            a{
                color: blue;
            }
        }

        // ambassador mode
        #totalSignUps{
            font-size: 40px;
        }
        p#instructions{
            .smallText;
            margin: 15px 0px 0;
            a{
                color: blue;
            }
        }
        button{
            margin-top: 15px;
        }
    }
}