.feedDropItemSong {
  align-items: center;
  justify-content: left;
}
.feedDropItemSong > a {
  display: flex;
  align-items: center;
}
.feedDropItemSong > a:hover {
  text-decoration: none;
}
.feedDropItemSong .imageHolder {
  position: relative;
}
.feedDropItemSong .imageHolder > img {
  border-radius: 20px;
  margin-right: 15px;
}
.feedDropItemSong .imageHolder > i {
  position: absolute;
  color: rgba(255, 255, 255, 0.9);
  font-size: 3em;
  left: 46px;
  top: 23px;
}
.feedDropItemSong .songName {
  font-size: 1.1rem;
}
.feedDropItemSong .footer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
  font-size: 0.8rem;
}
.feedDropItemSong .footer .playedInRoom {
  color: #f32196;
}
.feedDropItemSong .footer .playedInRoom a {
  text-decoration: underline;
  margin-left: 3px;
  font-weight: bold;
}
.feedDropItemSong .footer .playedInRoom a:hover {
  font-weight: 100;
}
.feedDropItemSong .footer .hashtags .hashtag {
  margin-left: 10px;
}
