.chatInputHolder {
  position: relative;
}
.chatInputHolder input {
  height: 32px;
  width: 261px;
  padding: 0px 1px 3px 9px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background: #d9d8d8;
  background: linear-gradient(15deg, #d9d8d8 0%, #faecec 9%, #f7f6f6 15%, #ffffff 30%);
}
.chatInputHolder .unreadIndicator {
  color: #f32196;
  position: absolute;
  top: 9px;
  right: 65px;
  -webkit-animation: blink 1.5s infinite;
  animation: blink 1.5s infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  cursor: pointer;
}
.chatInputHolder .unreadIndicator .blinkFunc from {
  opacity: 0;
}
.chatInputHolder .unreadIndicator .blinkFunc to {
  opacity: 0.5;
}
@-webkit-keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
.chatInputHolder .gifsOpener,
.chatInputHolder .chatEmojisOpener {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 0.9rem;
  color: #2196F3;
  padding: 7px 7px 6px;
  font-weight: bold;
  height: 32px;
  background-color: white;
}
.chatInputHolder .chatEmojisOpener {
  right: 30px;
  font-size: 15px;
  padding: 5px 7px 6px;
}
.chatInputHolder.private #chatInputSuggestedUsersHolder {
  bottom: 0;
  top: auto;
}
