@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#inboxHolder {
    position: fixed;
    top: 50px;
    right: calc( (100vw - @homepageWidth) / 2);
    z-index: 2;
    width: 300px;
    max-height: 80vh;
    color: @textColor;

    #noMessages {
        text-align: center;
        background-color: @glassBg_darker;
        color: white;
        padding: 10px 20px;
        .loader {
            width: 100%;
            height: 100%;
            position: absolute;
            text-align: center;

            > i {
                font-size: 2em;
            }
        }
    }

    .threadsScrollable {
        max-height: 80vh;
    }

    #buttonsHolder{
        > * {
            background-color: @color0;
            display: block;
            text-align: center;
            padding: 1px 0 4px;
            width: 100%;
            .smallText;
            cursor: pointer;
            margin-top: 2px;
            &:hover{
                text-decoration: none;
                background-color: @color0_dark;
            }
        }
    }
}

.inRoom {
    #inboxHolder{
        right: 0;
    }
}