@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.myCustomSwitch{
    display: flex;
    align-items: baseline;
    &.inline {
        display: inline-flex;
    }

    h6 {
        margin: 0px;
        font-weight: normal;
        cursor: default;
        padding-top: 1px;
    }
    &.disabled{
        .switch button {
            cursor: default !important;
            opacity: 0.7;
        }
    }
    .switch {
        margin-right: 5px;
        display: flex;

        button {
            background-color: white !important;
            color: #000 !important;
            padding: 2px 0px 2px !important;
            min-width: 35px !important;
            height: 17px !important;
            font-size: 9px !important;
            border-radius: 0px !important;
            z-index: 2 !important;


            &.active {
                background-color: @color0 !important;
                color: white !important;
            }
            &:first-of-type {
                border-radius: 4px 0px 0px 4px !important;
                border: @border !important;
            }
            &:last-of-type {
                border-radius: 0px 4px 4px 0px !important;
                border: @border !important;
            }
        }
    }


}
