#subscribeToUserBeatCasesPopup .modal-header {
  padding-bottom: 10px;
}
#subscribeToUserBeatCasesPopup .modal-body {
  padding: 0;
}
#subscribeToUserBeatCasesPopup .modal-body .spinnerHolder {
  text-align: center;
  padding-bottom: 17px;
}
#subscribeToUserBeatCasesPopup .modal-body #explanationTxt {
  text-align: center;
  margin: 0 50px;
}
#subscribeToUserBeatCasesPopup .modal-body #explanationTxt small {
  font-size: 1rem;
}
#subscribeToUserBeatCasesPopup .modal-body #explanationTxt a {
  color: #0c7cd5;
}
#subscribeToUserBeatCasesPopup .modal-body .listHolder {
  border-radius: 20px;
}
#subscribeToUserBeatCasesPopup .modal-body .ctaButton {
  text-align: center;
  padding: 15px;
}
