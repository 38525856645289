#slidingWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 620px;
  z-index: 1000;
  animation: fadeIn 1s;
  min-height: 100vh;
  background-color: white;
}
#slidingWindow .slidingWindowHeader {
  position: relative;
  z-index: 1;
  font-size: 1.4rem;
  background-color: #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  color: #404040;
  direction: rtl;
}
#slidingWindow .slidingWindowHeader .btnBack {
  order: 2;
}
#slidingWindow .slidingWindowContent #feed header {
  justify-content: right;
}
#slidingWindow .slidingWindowContent #feedDrop {
  border: none;
  height: 100vh;
}
#slidingWindow .preloaderWrapper {
  width: 100%;
  justify-content: center;
  display: flex;
  font-size: 50px;
  padding: 20px 0px;
  color: #6f6f6f;
}
