@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.roomTileHolder{
  text-align: left;
  position: relative;
  overflow: hidden;
  //box-shadow: 0 1px 6px rgba(25,25,34,.45);
  transition-property: box-shadow;
  transition-duration: .2s;
  .overlay{
    transition-duration: .4s;
    background-color: @color2;
    opacity: 0.4;
    border-radius: 5px;
  }
  &:hover{
    box-shadow: 5px 1px 6px rgba(25,25,34,.26);
    .overlay{
      background-color: transparent;
    }
  }
  .tooltip {
    .tinyText;
  }
  .room-header {
    color: #fff;
    padding: 6px 10px;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0px;
    display: flex;
    justify-content: space-between;
    .add-to-favorites{
      cursor: pointer;
    }
    .num-of-online-users-in-room {
      font-weight: 100;
    }
  }

  .holder{
    height: 100%;
    width: 250px;
  }

  &:hover a{
    text-decoration: none;
  }
  &:hover {
    .room-image {
      .tilePlay {
        animation: myanim 1s infinite;
        @keyframes myanim {
          50% {
             padding-bottom: 30px;
          }
       } 
      }
    }
  }

  .tileFloatIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    text-align: center;
  }

  .overlay {
    background-color: #333;
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 100%;
    .flex;
  }
  .room-image {
    position: relative;
    height: 140px;
    width: 250px;

    .overlayWrapper{
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      .fa-lock {
        color: #fff;
        font-size: 3em;
        opacity: 0.8;
        padding-bottom: 42px;
      }
      .entranceFeeBtn {
        z-index: 1;
        display: flex;
        flex-direction: column;
        margin-right: 8px;
        color: white;
        line-height: 26px;
        padding-bottom: 33px;
        .fa-key{
          .bigText;
        }
      }
    }

    .tilePlayWrapper{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .tilePlay {
        display: flex;
        align-items: center;
        font-size: 3em;
        justify-content: center;
        padding-bottom: 42px;
        //background-color: @color0_transparent;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .tileThumbnail {
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 5px;
    }
    .overlayWrapper, .tilePlayWrapper{
      i.premium {
        color: @color0;
      }
    }
  }

  .roomTile_defaultImg {
    height: 270px;
  }

  .curr-song-name {
    color: white;
    height: 25px;
    background-color: @color2_transparent;
    padding: 2px 10px 0px;
    position: relative;
    b {
      font-weight: 100;
    }
  }

  .roomTile_songName {
    display: block;
  }

  .roomTile_songName:hover {
    text-decoration: none;
  }

  .tileBottom{
    .usersEllipsis{
      color: white;
      font-weight: bold;
      position: relative;
      top: 10px;
    }
    .room-name {
      padding: 5px 8px;
      position: absolute;
      bottom: 0;
      background-color: rgba(255,255,255,0.75);
      width: 100%;
      .flex;
      justify-content: space-between;
      > div{
        .ellipsis;
        .nameAndLock{
          display: flex;
          align-items: center;
          font-weight: bold;
          max-width: 100px;
          .privateRoomLock{
            margin-right: 5px;
            .smallText;
          }
        }
      }
      .user-image{
        border-radius: 50%;
        width: 35px;
        height: 35px;
      }
      h3 {
        font-weight: normal;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 0 0 2px;
        .ellipsis;
        a{
          .ellipsis;
        }
        i {
          margin-right: 5px;
        }
        .name-holder {
          display: block;
          line-height: 20px;
        }
      }
      .room-tags {
        .smallText;
        color: @color2;
        .ellipsis;
        a{
          .ellipsis;
        }
      }
    }
  }
}

//must be outside, to catch mobile as well
.room-users-images {
  padding: 0 5px;
  display: flex;
  position: absolute;
  top: 61px;
  left: 3px;
  .room-user-image{
    margin-right: 5px;
    margin-bottom: 5px;
    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: -8px;
    }
    span{
      color: white;
      font-weight: bold;
      .biggerText;
      vertical-align: bottom;
    }
  }
}