@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";
@import (reference) "../../../../less/general";

#dropArea {
	background-color: white;
	display: flex;
	padding: 16px;
	border-radius: @borderRadius;
	.border;
	margin-top: 5px;
	#dropAreaProfileImg{
		width: 50px; height: 50px;
		border-radius: 50%;
	}
	#dropInput{
		width: 471px; //do not delete. handle case when text is cleared
		padding-left: 12px;
		#dropTypeSelection{
			font-weight: bold;
			display: flex;
			flex-wrap: wrap;
			label{
				font-size: inherit;
				margin-right: 5px;
			}
		}
		#dropContent{
			cursor: text;
			.normalText;
			min-height: 48px;
			padding-top: 12px;
			border-bottom: @lightGray 1px solid;
			margin-bottom: 12px;
			&:empty::before{
				content: attr(data-placeholder);
				color: gray;
			}
		}
		footer{
			color: @color0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			button{
				.mediumText;
				border-radius: 50px;
				padding: 5px 15px 8px;
				&.disabledBtn{
					opacity: 0.5;
					cursor: default;
				}
			}
			#enrichments{
				display: flex;
				align-items: center;
				.bigText;
				a{
					margin-right: 20px;
					line-height: 12px;
					color: black;
					&:hover{
						text-decoration: none;
					}
					&.disabled{
						color: lightgrey;
					}
				}
				#dropImg{
					.biggerText;
					position: relative;
					top: 1px;
				}
				#dropGif{
					.smallerText;
					font-weight: bold;
					padding: 2px 2px 3px;
					border: 2px solid black;
					line-height: 10px;
				}
			}
		}
	}

	#dropPollBuilder {
		width: 100%;
		position: relative;
		#dropPollBtnRemove {
			position: absolute;
			right: 0px;
			z-index: 11;
		}
	}

	#dropAttachment {
		width: 100%;
		position: relative;
		margin-bottom: @spacing;

		#attachmentBlocker {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 10;
		}

		#dropAttachmentBtnRemove {
			position: absolute;
			right: 0px;
			z-index: 11;
		}

		#dropAttachmentContent {
			text-align: center;

			.feedDropItemImage{
				img {
					max-width: 350px;
					max-height: 500px;
				}
			}
		}
	}
}