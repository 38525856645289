@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#beatzonePopup{

  min-width: 550px !important;

  .modal-body {
    .error {
      .smallText;
    }
    .my-row.upload-photo-buttons-holder,
    .my-row.error-and-notification {
      margin-top: 6px;
      .notification{
        color: @green;
      }
    }

    .edit-user-modal-user-image-holder {

      position: relative;
      padding: 0px;
      margin-right: 15px;

      .user-photo-options {
        position: absolute;
        top: -2px;
        z-index: 3;
        .fa-camera-retro {
          position: absolute;
          top: 0px;
          left: 0px;
          .mediumText;
          color: @color0;
        }
        > .options-holder {
          background-color: white;
          padding: 5px 5px 8px 8px;
          width: 187px;
          .smallText;
          margin-top: 16px;
          color: black;
        }
      }

      > img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      .userImageUploadSpinner {
        position: absolute;
        top: 20px;
        left: 26px;
        font-size: 2em;
      }
    }

    .upload-photo-buttons-holder {

      margin-top: 8px;
      .smallText;

      .btn-use-fb-image {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        i {
          margin-right: 1px;
        }
      }
      .btn-upload-image a {
        color: @color1 !important;
        text-decoration: underline;
      }
    }

    #edit-user-details-modal_top{
      display: flex;
      position: relative;
      margin-bottom: 15px;
      .user-details-holder{
        > div .fa-pen{
          .mediumText;
          color: @color0;
          margin-left: 5px;
          cursor: pointer;
        }
        #usernameAndCountry {
          display: flex;
          .countryHolder {
            margin-left: 15px;
          }
        }

        .my-row.user-name, .my-row.user-email {
          position: relative;
          display: flex;
          align-items: baseline;
          height: 30px;
          b{
            margin-right: 3px;
          }
          #txtUsername, #txtEmail {
            display: inline-block;
          }

          #txtUsername {
            width: 210px;
          }
          #txtEmail {
            width: 247px;
          }
          label{
            margin-right: 2px;
            margin-bottom: 0px;
          }

          span{
            max-width: 280px;
            float: left;
            position: relative;
          }
        }

        #logoutAndPasswordChange{
          .smallText;
          display: flex;
          .smallerText;
          margin-top: 12px;
          .logout{
            margin-right: 10px;
            #logout-link {
              color: @red;
            }
          }
          #change-password-group {
            margin-top: 5px;
            > div{
              display: flex;
              white-space: nowrap;
              align-items: baseline;
              b{
                font-size: 1rem;
                margin-right: 4px;

              }
              input {
                height: 28px;
                padding-left: 7px;
                .smallText;
              }
            }
          }
          .change-password{
            #change-password-link {
              cursor: pointer;
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }

        #privacy{
          display: flex;
          align-items: baseline;
          span{
            .smallerText;
            margin-left: 1px;
          }
        }
      }
    }

    #howPplSeeMyProfile {
      position: absolute;
      top: 2px;
      right: 32px;
    }

    #profileWatchesOpener {
      position: absolute;
      top: 2px;
      right: 55px;
    }

    .scrobble-holder {
      position: absolute;
      top: -3px;
      right: 0;
      > button{
        font-size: 0.9em !important;
        font-weight: normal !important;
        padding: 0px 5px 2px !important;
        background-color: #B00000;
        &.startScrobbling{
          background-color: #AE6868;
        }
      }
    }

    #beatZone_roomLists {
      margin-top: 22px;
      display: flex;
      flex-direction: column;
      h3 {
        .mediumText;
        font-weight: bold;
        margin-top: 14px;
      }
      .beatZone_roomLists_room {
        padding: 5px 7px 7px;
        font-size: 1rem;
        .fa-lock {
          margin-right: 5px;
          .smallText;
          position: relative;
          top: 1px;
        }
        .roomName {
          display: inline-block;
          max-width: 302px;
        }
        .roomOpener {
          color: @color1;
          max-width: 116px;
          display: inline-block;
        }
        .roomScore, .roomOpener{
          color: gray;
          margin-left: 5px;
        }
        &:nth-child(odd){
          background-color: @bgColor;
        }
        &:nth-child(even){
          background-color: @bgColor_lighter;
        }
      }
      .beatZone_roomLists_room:hover {
        background-color: @stripesBgHover;
        text-decoration: none;
        i {
          text-decoration: none;
        }
      }

      .rooms-tabs {
        position: relative;
        .tab {
          padding: 2px 14px 6px;
          width: auto;
          line-height: 27px;
          font-weight: bold;
          &.active {
            background-color: @bgColor;
          }
        }
        .globalScores {
          right: 0px;
          top: 6px;
          position: absolute;
          .smallText;
          a{
            text-decoration: underline;
          }
          .fa-star{
            color: @color2;
          }
        }
      }

      #roomListsHolder {
        max-height: 171px;
        background-color: @bgColor_lighter;
        .my-row {
          a:focus{
            color: #337ab7 !important;
          }
          .noBeatRooms {
            .smallText;
            text-align: center;
            padding: 8px 17px !important;
            color: black;
          }
          &.my-favorites-beatrooms {
            .beatZone_roomLists_room {
              position: relative;
              .btn-remove-from-favorites {
                position: absolute;
                right: 14px;
                padding: -2px 15px;
              }
            }
          }
        }
      }
    }

    #userSoundtracks{
      margin-top: 16px;
      label{
        i{
          margin-right: 5px;
        }
      }
      .soundtrack, .featuredSong{
        display: flex;
        padding: 5px 7px 7px;
        justify-content: space-between;
        font-size: 1rem;
        i{
          line-height: 20px;
        }
      }
    }

    #biographyHolder {
      margin-top: 15px;

      #noBiography{
        cursor: pointer;
        color: gray;
      }
      .biography, #noBiography{
        padding-left: 6px;
        font-size: 1rem;
      }
      .view{
        margin-top: 5px;
        background-color: @bgColor;
        border-radius: 25px;
        &:hover{
          background-color: @bgColor_lighter;
        }
      }
      textarea{
        width: 100%;
        margin-top: 5px;
        height: 120px;
        padding: 5px 10px;
        margin-bottom: 5px;
      }
      .edit{
        text-align: right;
      }
    }
  }

  .modal-footer {
    padding: 12px 8px 27px;
    margin-top: 20px !important;
    text-align: center;
    background-color: @bgColor_lighter;
    #beatsBalance {
      padding-bottom: 16px;
      i {
        margin-left: 1px;
        color: @color4;
      }
    }
  }

  position: relative;

  button {
    font-size: 0.95em;
    padding: 2px 15px 5px;
  }

  .adminIconsHolder {
    position: absolute;
    z-index: 4;
    .buttonsHolder {
      top: 10px;
      left: 10px;
      display: flex;
      flex-direction: row;
      gap: 10px;

      a {
        text-decoration: none;
      }
    }

    .impersonateForm {
      background-color: white;
      border: 1px solid;
      padding: 10px;
    }
  }

  .modal-body{
    display: table;
    width: 100%;
    .upload-image-holder{
      padding-right: 0px;
      > img {
        max-width: 150px;
      }
      .btn-upload-image {
        margin-top: 3px;
        cursor: pointer;
        font-size: 0.85em;
      }
      .btn-upload-image:hover {
        text-decoration: underline;
      }
      .buttons-holder {
        margin-top: 8px;
        font-size: 0.8em;
        .btn-use-fb-image {
          cursor: pointer;
          font-size: 0.85em;
          i {
            margin-right: 1px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    #accountDeletion{
      color: @red;
      .smallerText;
      margin-top: 15px;
      display: inline-block;
      float: right;
    }
  }
}