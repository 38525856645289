.copyPasteInput {
  position: relative;
}
.copyPasteInput > input {
  width: 100%;
  padding-right: 20px;
}
.copyPasteInput .copy {
  position: absolute;
  top: 4px;
  right: 5px;
}
