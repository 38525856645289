#moodsPopup .modal-body {
  color: #404040;
  z-index: 1;
  padding: 0px 15px 15px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
}
#moodsPopup .modal-body .moodsGroup {
  display: flex;
  flex-wrap: wrap;
  line-height: 25px;
  color: white;
  border-bottom: 1px dotted lightgray;
  padding: 13px 0;
}
#moodsPopup .modal-body .moodsGroup .mood {
  margin: 4px 10px;
  display: inline-block;
  color: white;
  font-size: 1rem;
  padding: 0 10px;
  border-radius: 20px;
  background-color: #b7b73d;
}
#moodsPopup .modal-body .moodsGroup .mood:hover {
  text-decoration: underline;
}
#moodsPopup .modal-body .moodsGroup .mood.bouncy,
#moodsPopup .modal-body .moodsGroup .mood.in_love,
#moodsPopup .modal-body .moodsGroup .mood.energized,
#moodsPopup .modal-body .moodsGroup .mood.loved,
#moodsPopup .modal-body .moodsGroup .mood.jolly,
#moodsPopup .modal-body .moodsGroup .mood.awesome,
#moodsPopup .modal-body .moodsGroup .mood.metal {
  background-color: #5ecc5d;
}
#moodsPopup .modal-body .moodsGroup .mood.amused,
#moodsPopup .modal-body .moodsGroup .mood.crazy,
#moodsPopup .modal-body .moodsGroup .mood.high,
#moodsPopup .modal-body .moodsGroup .mood.happy,
#moodsPopup .modal-body .moodsGroup .mood.rejuvenated,
#moodsPopup .modal-body .moodsGroup .mood.pleased,
#moodsPopup .modal-body .moodsGroup .mood.naughty,
#moodsPopup .modal-body .moodsGroup .mood.nostalgic {
  background-color: #48d9c2;
}
#moodsPopup .modal-body .moodsGroup .mood.angry,
#moodsPopup .modal-body .moodsGroup .mood.confused,
#moodsPopup .modal-body .moodsGroup .mood.discontent,
#moodsPopup .modal-body .moodsGroup .mood.sick,
#moodsPopup .modal-body .moodsGroup .mood.stressed,
#moodsPopup .modal-body .moodsGroup .mood.tired,
#moodsPopup .modal-body .moodsGroup .mood.bluesy,
#moodsPopup .modal-body .moodsGroup .mood.dark,
#moodsPopup .modal-body .moodsGroup .mood.scared {
  background-color: #93b2ec;
}
#moodsPopup .modal-body .moodsGroup .mood.exhausted,
#moodsPopup .modal-body .moodsGroup .mood.lonely,
#moodsPopup .modal-body .moodsGroup .mood.melancholy,
#moodsPopup .modal-body .moodsGroup .mood.very_angry {
  background-color: #e89b9b;
}
