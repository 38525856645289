@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";
@import (reference) "../../../../less/general";

#feedDrop{
	.box;
	.feedItem{
		margin-bottom: 0;
	}
	#dropArea{
		margin: 0 0 20px;
		padding: 0;
		border: none;
		//border-bottom: 1px solid @borderColor;
		background-color: @bgColor_lighter;
		padding: 10px;
		border-radius: 0;
	}
	.repliesHolder {
		padding-left: 40px;
	}
}