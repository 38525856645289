@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../less/variables";
@import (reference) "../../../less/general";

#feed{
	> header{
		text-align: right;
		color: @grayText;
		position: relative;
		padding: 7px 9px 9px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.leftEnd{
			.smallText;
		}
		.rightEnd{
			display: flex;
			align-items: center;
		}
		&:hover{
			.reloadNewest{
				text-decoration: none;
			}
		}
		.reloadNewest{
			margin-right: 10px;
		}

		.selectedHashtags {
			display: flex;
			justify-content: flex-end;
			.selectedHashtag{
				border-radius: 50px;
				padding: 3px 8px 4px;
				margin-right: 12px;
				background-color: @color2;
				color: white;
				white-space: nowrap;
				display: flex;
				align-items: baseline;
				.smallText;
				i{
					margin-right: 3px;
					position: relative;
					top: 1px;
				}
			}
		}

		#dropsFilterMenu {
			position: absolute;
			top: 22px;
			right: 0px;
			z-index: 1;
			text-align: left;
			background-color: @bgColor_lighter;
			padding: 10px 12px 5px;
			> div {
				margin-bottom: 5px;
				input{
					margin-right: 5px;
				}
			}
		}
		.featuredDrop{
			float:left;
			color: @color0_dark;
			margin-right: 30px;
			i{
				margin-right: 2px;
			}
		}
	}
	#drops{
		.border;
		border-radius: 20px;
		background-color: white;
		padding: 10px;

		.emptyList {
			text-align: center;
			font-weight: bold;
		}
	}
}