.header {
  letter-spacing: 0;
  left: 0;
  background-color: white;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  height: 40px;
  padding: 22px 0px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.header article {
  width: 1150px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
}
.header .title-holder {
  float: left;
}
.header h1.page-title {
  margin-top: 0;
}
.header #header_rightContainer,
.header #header_leftContainer {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
}
.header #header_leftContainer {
  left: 0px;
}
.header #header_leftContainer #mainLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding-bottom: 2px;
}
.header #header_leftContainer #mainLogo .logoLink:hover {
  text-decoration: none;
}
.header #header_leftContainer #mainLogo .logoLink #txtLogo {
  position: relative;
  top: 1px;
  font-size: 26px;
  font-weight: bold;
  display: inline;
}
.header #header_leftContainer #mainLogo .logoLink #logo {
  cursor: pointer;
  width: 100px;
  bottom: 1px;
  position: relative;
}
.header #header_leftContainer #mainLogo .logoLink span {
  margin-left: 5px;
  font-size: 1.2rem;
  text-shadow: 1px 1px 2px #f32196;
}
.header #header_leftContainer #plugdj {
  background-color: white;
  color: #f32196;
  margin-left: 20px;
  height: 12px;
  padding: 0 8px 19px;
  position: relative;
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 2px 2px #f32196;
  border-radius: 20px;
}
.header #header_leftContainer #plugdj:hover {
  text-decoration: none;
}
.header #header_centerContainer #room-details {
  display: flex;
}
.header #header_centerContainer #room-details #roomActions {
  display: flex;
  align-items: center;
}
.header #header_centerContainer #room-details #roomActions i {
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
.header #header_centerContainer #room-details #roomActions i#introJStrigger {
  color: #97daf7;
}
.header #header_centerContainer #room-details #roomActions #christmasModeButton i.off {
  color: #bbbaba;
}
.header #header_centerContainer #room-details .name {
  font-size: 1.2rem;
}
.header #header_centerContainer #room-details .fa-lock {
  margin-right: 6px;
  color: rgba(255, 255, 255, 0.5);
}
.header #header_centerContainer #headerPauseBtn {
  position: relative;
  font-size: 0.6em !important;
  border: 1px solid white;
  border-radius: 2px;
  padding: 2px 2px;
}
.header #header_rightContainer {
  padding-bottom: 1px;
  right: 0px;
}
.header #header_rightContainer #beats {
  position: relative;
}
.header #header_rightContainer #beats a {
  text-decoration: none;
}
.header #header_rightContainer #beats a img {
  height: 16px;
  bottom: 1px;
  position: relative;
}
.header #header_rightContainer #beats a .beatsAmount {
  position: relative;
  left: 3px;
  margin-left: 2px;
}
.header #header_rightContainer .login-holder {
  text-align: right;
  display: flex;
  justify-content: space-between;
  width: 80px;
  position: relative;
  bottom: 1px;
  font-weight: bold;
}
.header #header_rightContainer .user-details {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.header #header_rightContainer .user-details .user-image img {
  width: 25px;
  height: 25px;
  top: 1px;
  position: relative;
}
.header #header_rightContainer .user-details > * {
  margin-left: 17px;
}
.header #header_rightContainer .user-details .noMarginItem {
  margin-left: 0;
}
.header .musicMatchOpener {
  border-radius: 50%;
}
.header .musicMatchOpener img {
  cursor: pointer;
}
.header .musicMatchOpener.newMatches {
  box-shadow: -5px 0px 5px #f32196;
}
.header #impersonatedNotification {
  position: absolute;
  top: 51px;
  left: 0;
  width: 200px;
  height: 50px;
  background-color: red;
  color: white;
  text-align: center;
  z-index: 1000;
}
.header #impersonatedNotification .title {
  font-size: 16px;
  font-weight: bold;
}
.header #impersonatedNotification .logout {
  margin-top: 2px;
}
.inRoom {
  font-weight: normal;
}
.inRoom .header {
  font-weight: normal;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 22px 10px;
}
.inRoom .header article {
  width: 100%;
}
.inRoom .header #header_leftContainer #mainLogo .logoLink #txtLogo {
  font-weight: normal;
}
.inRoom .header #header_leftContainer #mainLogo #topMenu .opener {
  color: white;
}
