@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#create-room-modal {
  #roomManagementHolder{
    &.themesDisplayed{
      width: 582px;
    }
    #roomThemesManagement{
      padding: 0 12px;
    }
  }
  .modal-body {
    width: 500px;
    padding: 0px;
    padding-bottom: 14px;

    .subTitle {
      margin: 25px 0;
      text-align: center;
      padding: 0 30px;
    }

    #createRoomForm {
      margin-top: -6px;
      margin-bottom: 0px;
      section{
        margin-bottom: 22px;
        padding: 0 12px;
      }

      .inputArea{
        display: flex;
        white-space: nowrap;
        margin-bottom: 5px;
        #projectUrl {
          color: gray;
          font-weight: normal;
        }
      }
      tags-input[disabled] .tags, tags-input[disabled] .tags .input{
        background-color: @bgColor_lighter;
      }
      input{
        border: none;
        border-bottom: 1px solid #D2D2D2;
        padding: 0px;
        background-color: inherit;
        margin: 0 0 0 5px;
        height: 24px;
        position: relative;
        top: 1px;
        &[type=checkbox] {
          margin: 0;
        }
      }
      label {
        margin: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .btn {
        padding: 8px 22px 9px;
      }
      input[type="checkbox"], input[type="radio"]{
        margin-right: 4px;
      }

      tags-input .host{
        margin: 0;
      }

      tags-input{
        .tags{
          box-shadow: none;
          border: none;
          input{
            color: #4a4a4a;
            width: 353px !important;
          }
        }
      }
      #roomTags{
        .field-error{
          margin-top: 5px;
          font-weight: bold;
          padding-bottom: 0px;
        }

        .autocomplete {
          margin-top: 0px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-top: 0px;
          .suggestion-list {
            max-height: 168px;
            overflow-y: scroll;
          }
          .suggestion-item.selected {
            background-color: @lightGray;
            color: black;
          }
          .suggestion-item.selected em{
            background-color: transparent;
            color: black;
          }
        }
        #everything{
          display: flex;
          align-items: center;
          color: gray;
          .chkAllTagsLabel{
            font-weight: normal;
            font-size: 1rem;
            position: relative;
            top: 1px;
          }
          .mixtape{
            color: @textColor;
          }
        }
        .tagsSpinner {
          position: absolute;
          top: 43px;
          right: 10px;
        }

      }

      .outlineSection{
        border: 4px solid @color0;
        padding: 3px 7px;
      }
      #autoPlaySettings{
        label{
          display: flex;
          align-items: center;
          .fa-question-circle{
            margin-left: 4px;
            top: 1px;
            position: relative;
            font-size: 1rem;
          }
        }
        #autoPlaySettings_radios{
          display: flex;
          margin-top: 7px;
          &.disabledArea{
            opacity: 0.5;
          }
          > div{
            display: inline-flex;
            align-items: center;
            > label {
              margin-right: 10px;
              position: relative;
              top: 2px;
              .smallText;
              color: @grayText;
              font-weight: normal;
              white-space: nowrap;
            }
          }
        }
      }

      #roomDescriptionToggler{
        flex-direction: column;
        #descriptionArea{
          margin-top: 5px;
          #txtDescription {
            width: 100%;
            height: 60px;
            padding: 3px 5px;
            border: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            .semiSmallText;
          }
        }
      }

      #btnShowDescription, #btnSelectATheme {
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
          position: relative;
          top: 1px;
        }
      }

      #themeOptions {
        display: flex;
        align-items: center;
        a {
          margin-right: 10px;
          i {
            margin-right: 3px;
          }
        }
        img, video {
          width: 50px;
        }
      }

      #blockOthersSongsSwitch {
        display: flex;
        justify-content: space-between;
      }

      #roomType{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        #roomTypesRadios {
          display: flex;
          width: 100%;
          align-items: baseline;
          > div{ .flex; }
          label{
            margin-right: 8px;
            .smallText;
            position: relative;
            top: 1px;
            span{
              margin-right: 5px;
            }
            i:not(.fa-bolt){
              color: @color0;
              margin-right: 5px;
            }
            span{
              margin-right: 2px;
            }
          }
        }
      }

      #createEditRoomBtnWrapper {
        margin-top: 15px;
        margin-bottom: 5px;
        text-align: center;
        #beatsBalance{
          .smallText;
          margin-top: 10px;
          b{
            font-weight: 100;
          }
        }
      }

      #create-room_room-type {
        margin-top: 14px !important;
        label {
          font-weight: inherit;
          margin-top: 0px;
        }
        input[type=radio] {
          position: relative;
          top: 1px;
        }
      }
    }
  }
}
