.pollViewer .pollQuestion {
  margin-bottom: 10px;
}
.pollViewer .pollOption {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 2px;
  width: 300px;
  white-space: nowrap;
  line-height: 23px;
}
.pollViewer .pollOption label {
  margin-bottom: 0px;
}
.pollViewer .pollOption .ratioBar {
  position: absolute;
  height: 100%;
  background-color: #e6e6e6;
  border-radius: 4px;
  z-index: 0;
}
.pollViewer .pollOption .ratioBar.selected {
  background-color: #dfdfdf;
}
.pollViewer .pollOption .optionTxt {
  position: relative;
  z-index: 1;
  margin-left: 2px;
}
.pollViewer .pollOption .ratio {
  font-weight: 100;
  margin: 0 3px 0 5px;
}
.pollViewer .pollOption > label {
  width: 100%;
}
