@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/general";
@import (reference) "../../../../less/variables";

#redirectToPlatformPopup{
	text-align: center;
	.mediumText;
	p{
		font-size: 1rem;
		margin-top: 15px;
		color: gray;
	}
	button{
		margin-top: 18px;
		a:hover{
			text-decoration: none;
		}
	}
}