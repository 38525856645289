@staticBaseUrl: "https://cdn.beatsense.com/public";
#roomLevelPopup {
	#roomLevelFeatures{
		.roomLevelFeature{
			margin-bottom: 10px;
			.fa-check{
				margin-right: 5px;
			}
		}
	}
	#buttonsArea{
		margin-top: 15px;
		text-align: center;
	}
}
