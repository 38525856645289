@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#deleteAccountPopup {
	text-align: center;
	h3{
		color: @red;
	}
	p{
		margin: 16px 0 20px;
	}
	button{
		margin: 0 5px;
	}
}