@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";
@import (reference) "../../../../less/general";

#userAlertsOpener{
  position: relative;
  i{
    font-size: 1.1rem;
  }

  > .unread {
    position: absolute;
    background: #FF8181;
    width: 13px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    z-index: 1;
    top: 9px;
    left: 8px;
  }
}