.chatMessageInputReply {
  position: absolute;
  background-color: #4ba7dd;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 3px 5px 5px;
  display: flex;
}
.chatMessageInputReply .btnClose {
  position: absolute;
  top: 6px;
  right: 15px;
}
.chatMessageInputReply .userName {
  margin-right: 2px;
}
.chatMessageInputReply .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: inline-block;
}
