#userAlertsOpener {
  position: relative;
}
#userAlertsOpener i {
  font-size: 1.1rem;
}
#userAlertsOpener > .unread {
  position: absolute;
  background: #FF8181;
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  z-index: 1;
  top: 9px;
  left: 8px;
}
