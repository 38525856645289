#deleteAccountPopup {
  text-align: center;
}
#deleteAccountPopup h3 {
  color: #f53c56;
}
#deleteAccountPopup p {
  margin: 16px 0 20px;
}
#deleteAccountPopup button {
  margin: 0 5px;
}
