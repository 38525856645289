@staticBaseUrl: "https://cdn.beatsense.com/public";

#userReportPopup{
	.modal-body {
		text-align: right;
		textarea {
			width: 100%;
			height: 100px;
		}

		button {
			margin-top: 10px;
		}
	}
}