#togglePrivacyPopup .featuresList {
  list-style-type: disc;
  padding-left: 13px;
  max-width: 500px;
}
#togglePrivacyPopup .featuresList li {
  margin-bottom: 6px;
}
#togglePrivacyPopup .featuresList li span {
  color: gray;
  font-size: 0.9rem;
}
#togglePrivacyPopup footer {
  margin-top: 5px;
  text-align: center;
}
