#cookiesApproval {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 1002;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px;
  border-top: #f32196 3px solid;
}
#cookiesApproval button {
  font-weight: lighter;
}
#cookiesApproval a {
  color: blue;
  margin-right: 5px;
}
