@staticBaseUrl: "https://cdn.beatsense.com/public";
#chats {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 11; // has to be 11
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 45px;

    &.mobile {
        margin-right: 0px;
        bottom: 105px;
        z-index: 1002;
        padding: 0;
    }
}