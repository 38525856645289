@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.wikiOpener {
  position: relative;
  text-decoration: none !important;
  .fa-lightbulb{
    margin-right: 2px;
    &.cool{
      color: @beats;
    }
  }
  .fire-gif{
    width: 35px;
    position: absolute;
    bottom: 15px;
    right: -5px;
    z-index: 100;
  }
  .cool-data-gif{
    font-size: 22px;
    top: -1px;
    position: absolute;
    right: 0;
    color: @color0;
  }
}