#inboxHolder {
  position: fixed;
  top: 50px;
  right: calc((100vw - 1150px) / 2);
  z-index: 2;
  width: 300px;
  max-height: 80vh;
  color: #404040;
}
#inboxHolder #noMessages {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 20px;
}
#inboxHolder #noMessages .loader {
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
}
#inboxHolder #noMessages .loader > i {
  font-size: 2em;
}
#inboxHolder .threadsScrollable {
  max-height: 80vh;
}
#inboxHolder #buttonsHolder > * {
  background-color: #2196F3;
  display: block;
  text-align: center;
  padding: 1px 0 4px;
  width: 100%;
  font-size: 0.9rem;
  cursor: pointer;
  margin-top: 2px;
}
#inboxHolder #buttonsHolder > *:hover {
  text-decoration: none;
  background-color: #0c7cd5;
}
.inRoom #inboxHolder {
  right: 0;
}
