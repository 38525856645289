@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../../less/variables';
@import (reference) '../../../../../less/animations';

.chatMessageRowReply{
	background-color: rgba(255, 255, 255, 0.4);
	padding: 1px 5px 3px;
	margin: 4px 4px 4px 2px;
	max-width: 310px;
	text-align: left;
	border-radius: 5px 5px 5px 5px;
	.ellipsis;
	.username{
		color: #aed0cf;
	}
	.text{
		.ellipsis;
		max-width: 92%;
	}
}
