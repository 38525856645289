@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.songsCarousel {
  &.scroller {
    height: 91px;
    position: relative;
    overflow: hidden;

    .scrollButton{
      position: absolute;
      background-color: @whiteBg_transparent;
      border-radius: 50%;
      z-index: 1;
      .flex;
      height: 35px;
      width: 35px;
      font-size: 20px;
      top: 19px;
      color: @color0_dark;
      cursor: pointer;
      &.prev{
        left: 0;
        box-shadow: 5px 0px 1px @color2;
        text-shadow: -4px 0px 1px @color2;;
      }
      &.next{
        right: 0px;
        box-shadow: -5px 0px 1px @color2;
        text-shadow: 4px 0px 1px @color2;;
      }
    }
    .results {
      white-space: nowrap;
      position: absolute;
      top: 0;
      left: 0;
      &.centered{
        display: flex;
        justify-content: center;
        width: 100%;
      }
      > .result {
        display: inline-block;
        margin-right: 8px;
        width: 125px;
        position: relative;

        .song-thumb-wraper{
          height: 75px;
          overflow: hidden;
          border-radius: @borderRadius;
          img.song-thumb {
            position: relative;
            top: -12px;
            width: 130px;
          }
        }

        .duration {
          position: absolute;
          right: 0;
          top: 0px;
          padding: 0 9px;
          border-radius: 0 20px 0 20px;
          line-height: 17px;
          .smallerText;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.5);
        }
        .name {
          .smallerText;
          text-align: left;
          bottom: -19px;
          position: absolute;
          width: 100%;
          line-height: 14px;
          padding-bottom: 2px;
        }
        a:hover {
          text-decoration: none;
        }
      }
      .result:last-of-type {
        margin-right: 0;
      }
    }
  }
}
