#debugInfo {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80%;
  width: 30%;
  z-index: 1;
}
#debugInfo > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#debugInfo .bg {
  background-color: #000;
  opacity: 0.9;
}
#debugInfo .content {
  padding: 10px;
  overflow-y: auto;
  color: #fff;
}
#debugInfo .content .btnClose {
  position: absolute;
  top: 5px;
  right: 5px;
}
#debugInfo .content .item .title {
  font-weight: bold;
  text-decoration: underline;
}
#debugInfo .content .item .label {
  font-weight: bold;
  font-size: 1em;
  color: gray;
}
#debugInfo .content .item ul {
  list-style-type: disc;
}
