@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";
@import (reference) "../../../../less/general";

#userAlertsHolder{
  position: fixed;
  top: 48px;
  right: calc( (100vw - @homepageWidth) / 2);
  z-index: 2;
  .ps-scrollbar-y-rail{
    display: none;
  }
  .loaderHolder{
    right: 43px;
    position: absolute;
    > i {
      margin-top: 7px;
    }
  }

  .noAlerts{
    text-align: center;
    color: @textColor;
    .box;
  }

  .list{
    max-height: 76vh;
    margin-bottom: 2px;
  }

  .userAlertHolder{
    position: relative;
    background-color: white;
    padding: 12px;
    display: flex;
    min-width: 200px;
    margin-bottom: 2px;
    color: @textColor;

    a {
      cursor: pointer;
      &.btn{
        margin-top: 5px;
        padding: 3px 10px 6px;
      }
    }

    &.unread {
      background-color: @bgColor;
    }

    .iconWrapper, .imageWrapper{
      background-color: rgba(255,255,255,0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px 0 2px;
      i{
        .flex;
        margin: 0px;
        font-size: 25px;
        background-color: @bgColor;
        border-radius: 50%;
        padding: 10px 8px;
        width: 40px;
        height: 40px;
      }
    }

    .imageWrapper {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .contentWrapper{
      line-height: 20px;
      .alertMessage{
        white-space: normal;
        max-width: 300px;
        padding-right: 10px;
        a{
          color: @color2;
        }
      }
      .alertAddedAt{
        opacity: 0.7;
        .smallText;
      }
      .actionsHolder{
        display: flex;
        margin-bottom: 1px;
        :not(:last-child){
          margin-right: 5px;
        }
        button{
          padding: 3px 10px 6px;
          white-space: nowrap;
          margin-top: 5px;
        }
      }
    }
    .loaderHolder {
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      width: 100%;
      height: 100%;

      .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.5;
      }

      > i {
        margin-top: 14px;
        color: #fff;
      }
    }

    .btnDelete{
      position: absolute;
      top: 5px;
      right: 9px;
      .smallText;
    }
  }
  #clearAll{
    background-color: @color0;
    display: block;
    color: white;
    text-align: center;
    padding: 3px 0 4px;
    font-weight: bold;
    width: 100%;
    .smallText;
    &:hover{
      text-decoration: none;
      background-color: @color0_dark;
    }
  }
}

.inRoom {
  #userAlertsHolder{
    right: 5px;
  }
}