.pollBuilder {
  text-align: left;
}
.pollBuilder .pollOption {
  display: flex;
  margin-bottom: 3px;
  align-items: center;
}
.pollBuilder .pollOption label {
  margin-right: 12px;
  display: flex;
  width: 61px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.1rem !important;
}
.pollBuilder .pollOption input {
  width: 220px;
  border: 1px solid #979797;
}
.pollBuilder .pollOption .fa-trash-alt {
  color: #979797;
  font-size: 0.9rem;
  margin-left: 5px;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.pollBuilder .link {
  margin: 8px 0;
}
.pollBuilder .link a {
  color: #0c7cd5;
  font-size: 0.9rem;
}
