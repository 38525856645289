@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#feedDropPopup {
	.modal-header{
		margin-bottom: 0;
	}
	.modal-body{
		padding: 0px;
		#dropArea{
			margin-bottom: 20px;
			border: none;
		}
	}
	.btn-close{
		.mediumText;
	}
}