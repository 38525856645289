.bGPT {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: purple;
  border-radius: 50px;
  color: white;
  padding: 3px;
  font-family: 'OleoScript';
  cursor: default;
}
