@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#feedReDropPopup {
  width: 550px;
	.modal-body{
		padding: 0;
		.modal-header{
			margin-bottom: 0;
			.btn-close{
				.mediumText;
				margin: 10px 0;
			}
		}
		#dropArea{
			border: none;
		}
		.feedItem{
			padding: @spacing;
			border: none;
		}
	}
}