#loginOptions .login-bnt {
  color: #FFFFFF;
  display: table;
  line-height: 41px;
  margin-bottom: 9px;
  background-color: #3B5998;
  border-radius: 20px;
  padding-bottom: 1px;
  width: 100%;
  text-align: center;
}
#loginOptions .login-bnt:hover {
  background-color: #35518a;
}
#loginOptions .login-bnt > div {
  float: left;
}
#loginOptions .login-bnt > .login-option-logo {
  border-radius: 4px 0px 0px 4px;
  width: 40px;
  background-color: #31487b;
  position: absolute;
}
#loginOptions .login-bnt > .login-option-logo > i {
  line-height: inherit;
  font-size: 1.2rem;
}
#loginOptions .login-bnt > .login-option-label {
  padding: 0 15px;
  width: 100%;
}
#loginOptions .login-bnt.twitter {
  background-color: #00aced;
}
#loginOptions .login-bnt.twitter:hover {
  background-color: #0499d2;
}
#loginOptions .login-bnt.twitter > .login-option-logo {
  background-color: #0393ca;
}
#loginOptions .login-bnt.google {
  background-color: #D54934;
}
#loginOptions .login-bnt.google:hover {
  background-color: #c54330;
}
#loginOptions .login-bnt.google > .login-option-logo {
  background-color: #b93d2a;
}
#loginOptions .login-bnt.email {
  background-color: #239fa7;
}
#loginOptions .login-bnt.email:hover {
  background-color: #21979f;
}
#loginOptions .login-bnt.email > .login-option-logo {
  background-color: #1d8b92;
}
#loginOptions .login-bnt.twitter,
#loginOptions .login-bnt.google {
  margin-top: 1px;
}
