@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#mySubscriptionModal{
    .modal-body {
        padding:0 27px 18px;
        h4{
            font-weight: bold;
        }
        ul {
            .mediumText;
        }

        .transferMsg{
            color: blue;
        }

        .existsSubscriptionNotification{
            color: @green;
            text-align: center;
            margin: 0 auto;
        }

        #subscriptionFeatures{
            padding: 0;
            margin-top: 20px;
            li {
                .mediumText;
                margin-bottom: 3px;
                i{
                    font-weight: bold;
                    margin-right: 8px;
                }
            }
        }

        #subscriptions {
            margin-top: 25px;
            .subscription {
                font-size: 1.2rem;
                td{
                    padding: 0 8px 14px 0;
                    font-weight: bold;
                    &.packageName{
                        font-weight: normal;
                        b {
                            font-weight: bold;
                        };
                    }
                    &.subscriptionPrice{
                        font-size: 1rem;
                        .subscriptionTxt{
                            font-weight: normal;
                            color: @green;
                        }
                    }
                }
                button{
                    border: 0 none;
                    border-radius: 4px;
                    font-weight: bold;
                    margin-top: 2px;
                    padding: 3px 9px 6px;
                    margin-left: 6px;
                    background-color: @green4;
                    color: white;
                    white-space: nowrap;
                }
            }
        }

        #autorenew{
            text-align: center;
            b{
                font-weight: 100;
            }
        }

        .loader {
            font-size: 3.5em;
            margin: 0 auto;
            display: block;
            text-align: center;
        }
    }
}