@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#roomLevelsHolder{
  .roomLevelSwitch{
    align-items: center;
    display: flex;
    > div {
      display: flex;
      align-items: center;
      label{
        font-weight: normal;
        .normalText;
        cursor: pointer;
        display: flex;
        align-items: center;
        b{
          font-weight: bold;
          margin: 0 3px;
        }
      }
    }
    .beatsIcon{
      .smallText;
      margin-left: 3px;
      position: relative;
      top: 1px;
    }
    .proInfo {
      margin-left: 7px;
    }
  }
}