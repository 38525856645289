#userReportPopup .modal-body {
  text-align: right;
}
#userReportPopup .modal-body textarea {
  width: 100%;
  height: 100px;
}
#userReportPopup .modal-body button {
  margin-top: 10px;
}
