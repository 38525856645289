#roomLevelsHolder .roomLevelSwitch {
  align-items: center;
  display: flex;
}
#roomLevelsHolder .roomLevelSwitch > div {
  display: flex;
  align-items: center;
}
#roomLevelsHolder .roomLevelSwitch > div label {
  font-weight: normal;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
#roomLevelsHolder .roomLevelSwitch > div label b {
  font-weight: bold;
  margin: 0 3px;
}
#roomLevelsHolder .roomLevelSwitch .beatsIcon {
  font-size: 0.9rem;
  margin-left: 3px;
  position: relative;
  top: 1px;
}
#roomLevelsHolder .roomLevelSwitch .proInfo {
  margin-left: 7px;
}
