@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../../less/variables";

.pollViewer {
    .pollQuestion {
        margin-bottom: 10px;
    }

    .pollOption {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-bottom: 2px;
        width: 300px;
        white-space: nowrap;
        line-height: 23px;
        label{
            margin-bottom: 0px;
        }
        .ratioBar{
            position: absolute;
            height: 100%;
            background-color: @bgColor;
            border-radius: 4px;
            z-index: 0;
            &.selected{
                background-color: @bgColor_hover;
            }
        }
        .optionTxt{
            position: relative;
            z-index: 1;
            margin-left: 2px;
        }
        .ratio{
            font-weight: 100;
            margin: 0 3px 0 5px;
        }

        > label {
            width: 100%;
        }
    }
}
