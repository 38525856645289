@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../less/variables";

#slidingWindow {
    position: fixed;
    top: 0;
    left: 0;
    width: 620px;
    z-index: 1000;
    animation: fadeIn 1s;
    min-height: 100vh;
    background-color: white;

    .slidingWindowHeader {
        position: relative;
        z-index: 1;
        .bigText;
        background-color: @bgColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 15px;
        color: @textColor;
        direction: rtl;
        .btnBack{
            order: 2;
        }
    }

    .slidingWindowContent {
        #feed{
            header{
                justify-content: right;
            }
        }
        #feedDrop{
            border: none;
            height: 100vh;
        }
    }

    .preloaderWrapper{
        width: 100%;
        justify-content: center;
        display: flex;
        font-size: 50px;
        padding: @spacing 0px;
        color: @grayText;
    }
}