#changeUserCountryPopup input {
  border: 1px solid #D2D2D2;
  width: 100%;
  padding: 10px;
}
#changeUserCountryPopup .countries {
  margin-top: 10px;
  text-align: left;
  max-height: 350px;
}
#changeUserCountryPopup .countries .country {
  padding: 6px 0;
}
#changeUserCountryPopup .countries .country .flag {
  margin-right: 5px;
}
#changeUserCountryPopup .countries .country:hover {
  background-color: #dfdfdf;
}
#changeUserCountryPopup #selectedCountry {
  margin-top: 10px;
  font-size: 1.2rem;
}
#changeUserCountryPopup #selectedCountry .flag-icon {
  margin-right: 4px;
}
#changeUserCountryPopup #selectedCountry button {
  margin-top: 10px;
}
