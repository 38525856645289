@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../less/variables';

.chatMessageRow {
    padding: 3px 9px 7px 9px;
    position: relative;
    width: 100%;
    color: white;
    background-color: @glassBg_evenDarker;

    &.rtl {
        direction: rtl;

        .sendIndicator {
            left: 6px;
            right: auto;
        }

        .messageColor {
            margin-right: 14px;
        }
    }

    .messageColor{
        min-width: 4px;
        height: 100%;
        position: absolute;
        left: 2px;
        top: 0;
    }

    .userName{
        a{
            color: @color0_light;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 80px;
            display: inline-block;
            line-height: 16px;
            top: 3px;
            position: relative;
        }
    }

    .separator{
        position: relative;
        right: 2px;
    }

    .msgTxt{
        a:not(.user-link-inline){
            color: @twitter;
            &:hover{
                text-decoration: underline;
            }
        }
        .user-link-inline{
            color: @color2;
        }
    }
    img{
        max-height: 110px;
    }

    .sendIndicator {
        position: absolute;
        bottom: 1px;
        right: 16px;

        > i {
            font-size: 0.7em;
        }

        &.read {
            color: yellow;
        }
    }

    &:hover {
        .chatMessageHoverOptions {
            display: flex;
        }
    }
}

.chatMessageColorTooltip{
    max-width: 160px;
}
