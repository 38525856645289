@staticBaseUrl: "https://cdn.beatsense.com/public";
#songModal {

  .modal-body {
    //min-height: 400px;

    .songNotFoundError {
      color: red;
      text-align: center;

      button {
        margin-top: 10px;
      }
    }
  }

  .spinnerHolder {
    text-align: center;
    padding-top: 132px;

    #mySpinner {
      font-size: 5em;
    }
  }
}