#ambassadorProgramPopup .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  white-space: inherit;
  flex-direction: column;
  text-align: center;
}
#ambassadorProgramPopup .modal-body p {
  font-size: 1.1rem;
}
#ambassadorProgramPopup .modal-body #ambassadorLink {
  width: 100%;
}
#ambassadorProgramPopup .modal-body #ambassadorLink .copyPasteInput {
  position: relative;
  margin: 15px 10px 6px;
}
#ambassadorProgramPopup .modal-body #shareOptions {
  width: 285px;
}
#ambassadorProgramPopup .modal-body #tip {
  font-size: 0.9rem;
  margin-bottom: 5px;
}
#ambassadorProgramPopup .modal-body #tip i {
  color: #c1b368;
  margin-right: 5px;
}
#ambassadorProgramPopup .modal-body #thatSimple {
  margin: 15px 0;
  color: #2196F3;
  text-decoration: underline;
}
#ambassadorProgramPopup .modal-body #thatSimpleParagraph {
  width: 100%;
  white-space: break-spaces;
  text-align: center;
}
#ambassadorProgramPopup .modal-body #thatSimpleParagraph a {
  color: blue;
}
#ambassadorProgramPopup .modal-body #totalSignUps {
  font-size: 40px;
}
#ambassadorProgramPopup .modal-body p#instructions {
  font-size: 0.9rem;
  margin: 15px 0px 0;
}
#ambassadorProgramPopup .modal-body p#instructions a {
  color: blue;
}
#ambassadorProgramPopup .modal-body button {
  margin-top: 15px;
}
