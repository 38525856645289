@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/general";
@import (reference) "../../../../less/variables";

#moodsPopup {
	.modal-body {
		color: @textColor;
		z-index: 1;
		padding: 0px 15px 15px;
		display: flex;
		flex-wrap: wrap;
		.mediumText;

		.moodsGroup{
			display: flex;
			flex-wrap: wrap;
			line-height: 25px;
			color: white;
			border-bottom: 1px dotted lightgray;
			padding: 13px 0;
			.mood{
				margin: 4px 10px;
				display: inline-block;
				color: white;
				font-size: 1rem;
				padding: 0 10px;
				border-radius: 20px;
				&:hover{
					text-decoration: underline;
				}
				background-color: #b7b73d;
				&.bouncy, &.in_love, &.energized, &.loved, &.jolly, &.awesome, &.metal{
					background-color: #5ecc5d;
				}
				&.amused, &.crazy, &.high, &.happy, &.rejuvenated, &.pleased, &.naughty, &.nostalgic{
					background-color: #48d9c2;
				}
				&.angry, &.confused, &.discontent, &.sick, &.stressed, &.tired, &.bluesy, &.dark, &.scared{
					background-color: #93b2ec;
				}
				&.exhausted, &.lonely, &.melancholy, &.very_angry{
					background-color: #e89b9b;
				}
			}
		}
	}
}