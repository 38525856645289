.feedItem {
  display: flex;
  padding: 13px 6px 13px 0;
  position: relative;
  border-bottom: 1px solid #eaeaea;
}
.feedItem .dropperImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 12px;
}
.feedItem .feedContent {
  width: 100%;
}
.feedItem .feedContent header .dropperName {
  font-weight: bold;
  font-size: 1.2rem;
}
.feedItem .feedContent header .separator {
  margin: 0 5px;
}
.feedItem .feedContent header .addedAt {
  color: #6f6f6f;
  font-size: 0.9rem;
}
.feedItem .feedContent header .actionButtons > a {
  float: right;
  color: lightgray;
  margin-left: 10px;
}
.feedItem .feedContent header .actionButtons > a:hover {
  color: #6f6f6f;
}
.feedItem .feedContent header .redroppedLabel {
  color: #f32196;
  font-size: 0.9rem;
}
.feedItem .feedContent .dropContent {
  margin-top: 10px;
  display: inline-block;
}
.feedItem .feedContent .dropContent a {
  color: #0c7cd5;
}
.feedItem .feedContent .attachment {
  margin-top: 14px;
  justify-content: left;
  display: flex;
}
.feedItem .feedContent .attachment img {
  border-radius: 20px;
}
.feedItem .feedContent .attachment .feedDropItemImage img {
  max-width: 350px;
  max-height: 350px;
}
.feedItem .feedContent .originalFeedDrop {
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  margin-top: 12px;
  display: flex;
}
.feedItem .feedContent footer {
  margin-top: 15px;
}
.feedItem .feedContent footer .dropAction {
  text-decoration: none;
  cursor: pointer;
}
.feedItem .feedContent footer .dropAction i {
  color: black;
  margin-right: 20px;
  font-size: 1.2rem;
}
.feedItem .feedContent footer .dropAction.disabled {
  color: lightgrey;
}
.feedItem .feedContent footer .dropAction.disabled i {
  color: lightgrey;
}
.feedItem .feedContent footer .dropAction:hover i {
  transition-duration: 0.3s;
  transition-property: text-shadow;
  text-shadow: 2px 1px 0px #f32196;
}
.feedItem .feedContent footer .dropAction .amount {
  margin-right: 6px;
  font-size: 0.9rem;
}
.feedItem .blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
