@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#mySubscriptionPurchaseModal{
    .modal-body {
        text-align: center;
        font-weight: bold;
        .mediumText;
        .fa-bolt{
            margin-top: 10px;
            font-size: 30px;
        }
    }
}