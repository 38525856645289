@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.songMoodsSearch {
  position: relative;
  .spinner {
    position: absolute;
    top: 0;
    left: 0;
  }
}
