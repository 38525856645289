@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/general";
@import (reference) "../../../../less/variables";

#postFavoritePopup{
	text-align: center;
	p{
		i, a{
			color: @color0;
		}
		i{
			.mediumText;
		}
		a{
			text-decoration: underline;
		}
	}
	.buttonsHolder {
		.flex;
		button{
			margin: 10px 10px;
			padding-top: 5px;
			padding-bottom: 8px;
			width: 100px;
		}
	}
}