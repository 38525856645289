#roomAccessRequestPopup {
  text-align: center;
}
#roomAccessRequestPopup .modal-body {
  font-size: 1.2rem;
}
#roomAccessRequestPopup .modal-body section {
  margin-bottom: 15px;
}
#roomAccessRequestPopup .modal-body section a {
  color: #0c7cd5;
}
#roomAccessRequestPopup .modal-body #notEnoughBeats {
  font-size: 0.9rem;
  color: #f53c56;
  margin-top: 10px;
}
#roomAccessRequestPopup .modal-body #notEnoughBeats a {
  color: #404040;
  text-decoration: underline;
}
