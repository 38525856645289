.chargeForRoomEntrance {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.chargeForRoomEntrance input[type=number] {
  border: 1px solid #e8e8e8 !important;
  font-size: 1.1rem;
  width: 42px;
  height: 20px;
  padding: 0 4px !important;
  margin: 0px !important;
}
.chargeForRoomEntrance input {
  margin-right: 3px !important;
}
.chargeForRoomEntrance label {
  font-weight: normal !important;
  font-size: 1rem !important;
}
.chargeForRoomEntrance .beatsIcon {
  margin-right: 5px;
}
