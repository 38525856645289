@staticBaseUrl: "https://cdn.beatsense.com/public";
#mobileLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  padding-top: 41px;
}