@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#musicMatchesPopup {
  width: 600px;
  .musicTagSearch{
    margin-bottom: 8px;
  }
  #matches{
    max-height: 395px;
    .match{
      display: flex;
      padding: 10px 5px;
      justify-content: space-between;

      &.isNew {
          //background-color: lightblue;
      }

      &:hover{
        background-color: @bgColor;
      }
      > div{
        display: flex;
      }
      img{
        width: 60px;
        height: 60px;
        margin-right: 10px;
        border-radius: 50%;
        &.isNew{
          border: 5px solid @color0;
        }
      }
      .matchName{
        .bigText;
        font-weight: bold;
        display: flex;
        align-items: center;
        .onlineMatch{
          .smallText;
          margin-left: 10px;
          position: relative;
          top: 2px;
          .fa-circle{
            color: @onlineGreen;
          }
        }
      }
      .matchTags{
        display: flex;
        align-items: center;
        margin-top: 5px;
        > div{
          color: white;
          margin-right: 5px;
          .smallText;
          background-color: @color2_transparent;
          border-radius: 70px;
          padding: 0 10px;

          &.match {
            background-color: @green4_dark;
          }
        }
      }
      .superMatch{
        margin-left: 20px;
        font-weight: 100;
        color: @color2;
        text-shadow: 2px 0px 0px @color0;
        float: right;
        .bigText;
        position: relative;
        top: 1px;
      }
    }
  }
  .fa-spin{
    text-align: center;
    width: 100%;
    font-size: 2rem;
  }
  #noMatches{
    .mediumText;
  }
}
