@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../less/variables";

.header {
    letter-spacing: 0;
    left: 0;
    background-color: white;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
    height: 40px;
    padding: 22px 0px;
    border-bottom: 1px solid #e8e8e8;

    .flex;

    article{
        width: @homepageWidth;
        .flex;
        position: relative;
    }
    .title-holder {
        float: left;
    }
    h1.page-title {
        margin-top: 0;
    }
    #header_rightContainer, #header_leftContainer{
        display: flex;
        align-items: center;
        height: 100%;
        position: absolute;
    }
    #header_leftContainer {
        left: 0px;
        #mainLogo{
            .flex;
            padding-bottom: 2px;
            .logoLink{
                &:hover{
                    text-decoration: none;
                }
                #txtLogo{
                    position: relative;
                    top: 1px;
                    font-size: 26px;
                    font-weight: bold;
                    display: inline;
                }
                #logo {
                    cursor: pointer;
                    width: 100px;
                    bottom: 1px;
                    position: relative;
                }
                span {
                    margin-left: 5px;
                    .mediumText;
                    text-shadow: 1px 1px 2px @color2;
                }
            }
        }
        #plugdj{
            background-color: white;
            color: @color2;
            margin-left: 20px;
            height: 12px;
            padding: 0 8px 19px;
            position: relative;
            font-weight: bold;
            .smallText;
            box-shadow: 2px 2px @color2;
            border-radius: @borderRadius;
            &:hover{
                text-decoration: none;
            }
        }
    }
    #header_centerContainer {
        #room-details{
            display: flex;
            #roomActions{
                display: flex;
                align-items: center;
                i{
                    cursor: pointer;
                    margin-right: 10px;
                    position: relative;
                    top: 1px;
                    &#introJStrigger{
                        color: @color0_light;
                    }
                }
                #christmasModeButton{
                    i.off{
                        color: #bbbaba;
                    }
                }
            }
            .name{
                .mediumText;
            }
            .fa-lock{
                margin-right: 6px;
                color: rgba(255,255,255,0.5)
            }
        }
        #headerPauseBtn{
            position: relative;
            font-size: 0.6em !important;
            border: 1px solid white;
            border-radius: 2px;
            padding: 2px 2px;
        }
    }
    #header_rightContainer {
        padding-bottom: 1px;
        right: 0px;
        #beats{
            position: relative;
            a{
                text-decoration: none;
                img{
                    height: 16px;
                    bottom: 1px;
                    position: relative;
                }
                .beatsAmount{
                    position: relative;
                    left: 3px;
                    margin-left: 2px;
                }
            }
        }
        .login-holder {
            text-align: right;
            display: flex;
            justify-content: space-between;
            width: 80px;
            position: relative;
            bottom: 1px;
            font-weight: bold;
        }
        .user-details{
            .flex;
            .user-image {
                img {
                    width: 25px;
                    height: 25px;
                    top: 1px;
                    position: relative;
                }
            }
            > * {
                margin-left: 17px;
            }
            .noMarginItem{
                margin-left: 0;
            }
        }
    }
    .musicMatchOpener{
        border-radius: 50%;
        img {
            .clickable;
        }

        &.newMatches {
            box-shadow: -5px 0px 5px @color2;
        }
    }

    #impersonatedNotification {
        position: absolute;
        top: 51px;
        left: 0;
        width: 200px;
        height: 50px;
        background-color: red;
        color: white;
        text-align: center;
        z-index: 1000;

        .title {
            font-size: 16px;
            font-weight: bold;
        }

        .logout {
            margin-top: 2px;
        }
    }
}

.inRoom {
    font-weight: normal;
    .header {
        font-weight: normal;
        color: white;
        background-color: @glassBg_medium;
        border: none;
        padding: 22px 10px;
        article{
            width: 100%;
        }
        #header_leftContainer {
            #mainLogo {
                .logoLink {
                    #txtLogo {
                        font-weight: normal;
                    }
                }
                #topMenu .opener{
                    color: white;
                }
            }
        }
    }
}