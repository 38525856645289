.myCustomSwitch {
  display: flex;
  align-items: baseline;
}
.myCustomSwitch.inline {
  display: inline-flex;
}
.myCustomSwitch h6 {
  margin: 0px;
  font-weight: normal;
  cursor: default;
  padding-top: 1px;
}
.myCustomSwitch.disabled .switch button {
  cursor: default !important;
  opacity: 0.7;
}
.myCustomSwitch .switch {
  margin-right: 5px;
  display: flex;
}
.myCustomSwitch .switch button {
  background-color: white !important;
  color: #000 !important;
  padding: 2px 0px 2px !important;
  min-width: 35px !important;
  height: 17px !important;
  font-size: 9px !important;
  border-radius: 0px !important;
  z-index: 2 !important;
}
.myCustomSwitch .switch button.active {
  background-color: #2196F3 !important;
  color: white !important;
}
.myCustomSwitch .switch button:first-of-type {
  border-radius: 4px 0px 0px 4px !important;
  border: 1px solid #D2D2D2 !important;
}
.myCustomSwitch .switch button:last-of-type {
  border-radius: 0px 4px 4px 0px !important;
  border: 1px solid #D2D2D2 !important;
}
