@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../../less/variables";
@import (reference) "../../../../../less/general";
.feedDropItemSong {
	align-items: center;
	justify-content: left;

	> a {
		display: flex;
		align-items: center;
		&:hover{
			text-decoration: none;
		}
	}

    .imageHolder {
		position: relative;
		> img{
			border-radius: @borderRadius;
			margin-right: 15px;
		}
		> i {
			position: absolute;
			color: @whiteBg_transparent;
			font-size: 3em;
			left: 46px;
			top: 23px;
		}
	}
	
	.songName{
		font-size: 1.1rem;
	}

	.footer {

		display: flex;
		flex-direction: row;
		margin-top: 10px;
		justify-content: space-between;
		.smallerText;

		.playedInRoom{
			color: @color2;
			a{
				text-decoration: underline;
				margin-left: 3px;
				font-weight: bold;
				&:hover{
					font-weight: 100;
				}
			}
		}

		.hashtags {
			.hashtag {
				margin-left: 10px;
			}
		}
	}
}