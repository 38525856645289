#musicMatchesPopup {
  width: 600px;
}
#musicMatchesPopup .musicTagSearch {
  margin-bottom: 8px;
}
#musicMatchesPopup #matches {
  max-height: 395px;
}
#musicMatchesPopup #matches .match {
  display: flex;
  padding: 10px 5px;
  justify-content: space-between;
}
#musicMatchesPopup #matches .match:hover {
  background-color: #e6e6e6;
}
#musicMatchesPopup #matches .match > div {
  display: flex;
}
#musicMatchesPopup #matches .match img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50%;
}
#musicMatchesPopup #matches .match img.isNew {
  border: 5px solid #2196F3;
}
#musicMatchesPopup #matches .match .matchName {
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
#musicMatchesPopup #matches .match .matchName .onlineMatch {
  font-size: 0.9rem;
  margin-left: 10px;
  position: relative;
  top: 2px;
}
#musicMatchesPopup #matches .match .matchName .onlineMatch .fa-circle {
  color: #39e737;
}
#musicMatchesPopup #matches .match .matchTags {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
#musicMatchesPopup #matches .match .matchTags > div {
  color: white;
  margin-right: 5px;
  font-size: 0.9rem;
  background-color: rgba(249, 44, 243, 0.9);
  border-radius: 70px;
  padding: 0 10px;
}
#musicMatchesPopup #matches .match .matchTags > div.match {
  background-color: #53bd87;
}
#musicMatchesPopup #matches .match .superMatch {
  margin-left: 20px;
  font-weight: 100;
  color: #f32196;
  text-shadow: 2px 0px 0px #2196F3;
  float: right;
  font-size: 1.4rem;
  position: relative;
  top: 1px;
}
#musicMatchesPopup .fa-spin {
  text-align: center;
  width: 100%;
  font-size: 2rem;
}
#musicMatchesPopup #noMatches {
  font-size: 1.2rem;
}
