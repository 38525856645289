.subscriptionBattery {
  line-height: 0.7;
  text-align: center;
}
.subscriptionBattery .text {
  font-size: 0.8em;
}
.subscriptionBattery .icon {
  font-size: 1.4em;
}
.subscriptionBattery .icon.fa-battery-empty {
  color: #404040;
}
.subscriptionBattery .icon.fa-battery-quarter {
  color: #FF8181;
}
.subscriptionBattery .icon.fa-battery-half {
  color: #f32196;
}
.subscriptionBattery .icon.fa-battery-three-quarters {
  color: #2196F3;
}
.subscriptionBattery .icon.fa-battery-full {
  color: #4CAF50;
}
.inRoom .subscriptionBattery .icon.fa-battery-empty {
  color: white;
}
