@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#loginOptions {
    .login-bnt {
        color: #FFFFFF;
        display: table;
        line-height: 41px;
        margin-bottom: 9px;
        background-color: #3B5998;
        border-radius: @borderRadius;
        padding-bottom: 1px;
        &:hover{
            background-color: #35518a;
        }
        width: 100%;
        text-align: center;

        > div {
            float: left;
        }

        > .login-option-logo {
            border-radius: 4px 0px 0px 4px;
            width: 40px;
            background-color: #31487b;
            position: absolute;

            > i {
                line-height: inherit;
                .mediumText;
            }
        }

        > .login-option-label {
            padding: 0 15px;
            width: 100%;
        }

        &.twitter{

            background-color: @twitter;
            &:hover{
                background-color: #0499d2;
            }

            > .login-option-logo {
                background-color: #0393ca;
            }
        }
        &.google{

            background-color: @googlePlus;
            &:hover{
                background-color: #c54330;
            }

            > .login-option-logo {
                background-color: #b93d2a;
            }
        }
        &.email{

            background-color: #239fa7;
            &:hover{
                background-color: darken(#239fa7, 2%);
            }

            > .login-option-logo {
                background-color: #1d8b92;
            }
        }

        &.twitter, &.google{
            margin-top: 1px;
        }
    }
}