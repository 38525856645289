@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../less/variables';

#moodsOpener {
  .curr-mood{
    display: flex;
    align-items: baseline;
    div{
      display: inline-block;
      text-decoration: underline;
      border-radius: 4px;
      height: 21px;
      top: 1px;
      position: relative;
      margin: 2px 3px;
      span{
        position: relative;
        bottom: 1px;
      }
      &:hover{
        transition-duration: .3s;
        background-color: rgba(255,255,255,0.1);
      }
    }
  }
}

.mood{
  color: #f5f59b;
  &.bouncy, &.in_love, &.energized, &.loved, &.jolly, &.awesome, &.metal{
    color: #7cea7b;
  }
  &.amused, &.crazy, &.high, &.happy, &.rejuvenated, &.pleased, &.naughty, &.nostalgic{
    color: #b8fff3;
  }
  &.angry, &.confused, &.discontent, &.sick, &.stressed, &.tired, &.bluesy, &.dark, &.scared{
    color: #bed6fc;
  }
  &.exhausted, &.lonely, &.melancholy, &.very_angry{
    color: #ffd4d4;
  }
}