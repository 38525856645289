@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../less/variables';
@import (reference) '../../../../less/animations';

.chatInputHolder {
    position: relative;
    input {
        height: 32px;
        width: 261px;
        padding: 0px 1px 3px 9px;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        background: rgb(217,216,216);
        background: linear-gradient(15deg, rgba(217,216,216,1) 0%, rgba(250,236,236,1) 9%, rgba(247,246,246,1) 15%, rgba(255,255,255,1) 30%);
    }
    .unreadIndicator {
        color: @color2;
        position: absolute;
        top: 9px;
        right: 65px;
        .blink(0,0.5,1.5s);
        cursor: pointer;
    }

    .gifsOpener, .chatEmojisOpener {
        position: absolute;
        top: 0px;
        right: 0px;
        .smallText;
        color: @color0;
        padding: 7px 7px 6px;
        font-weight: bold;
        height: 32px;
        //box-shadow: inset 0px 0px 1px 1px lightgrey;
        background-color: white;
    }

    .chatEmojisOpener {
        right: 30px;
        font-size: 15px;
        padding: 5px 7px 6px;
    }

    &.private {
        #chatInputSuggestedUsersHolder {
            bottom: 0;
            top: auto;
        }
    }
}
