#mobileFooter {
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: flex-end;
  padding-right: 2px;
  background-color: white;
  border: 2px solid lightgrey;
}
#mobileFooter button {
  border-radius: 50% 50% 0;
  position: relative;
  bottom: 2px;
  height: 60px;
  width: 60px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-shadow: 2px 2px 0px #f32196;
  box-shadow: 4px 2px 0px #f32196;
}
#mobileFooter button.createRoomBtn {
  font-size: 50px;
}
#mobileFooter button span {
  transform: rotate(0deg);
  transition: transform 0.15s;
}
#mobileFooter button.songSearchDisplayed {
  background-color: #6f6f6f;
  text-shadow: 2px 2px 0px #333;
  box-shadow: 2px 2px 0px #333;
}
#mobileFooter button.songSearchDisplayed span {
  transform: rotate(45deg);
  left: 4px;
  top: 1px;
  position: relative;
}
#mobileFooter #homePageMobileButtons {
  display: flex;
  align-items: center;
}
#mobileFooter #homePageMobileButtons > * {
  margin: 8px;
  font-size: 0.9rem;
  font-weight: bold;
}
#mobileFooter #homePageMobileButtons .active {
  font-weight: 200;
  text-decoration: underline;
  font-size: 1.2rem;
}
#mobileFooter #roomFooterActions > * {
  font-size: 30px;
  font-size: 25px;
  margin: 15px;
  display: inline;
}
#mobileFooter #roomFooterActions > * .fa-sign-out-alt {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
#mobileFooter #roomChatOpener i.opened {
  color: #2196F3;
}
#mobileFooter #roomChatOpener i.closed {
  color: #404040;
}
#mobileFooter #roomChatOpener i.unread {
  -webkit-animation: blinkBgFunc2 2s infinite;
  animation: blinkBgFunc2 2s infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
#mobileFooter #roomChatOpener i.unread .blinkBgFunc from {
  color: #404040;
}
#mobileFooter #roomChatOpener i.unread .blinkBgFunc to {
  color: #f32196;
}
@-webkit-keyframes blinkBgFunc2 {
  from {
    color: #404040;
  }
  to {
    color: #f32196;
  }
}
@keyframes blinkBgFunc2 {
  from {
    color: #404040;
  }
  to {
    color: #f32196;
  }
}
