#mobileCurrSongControls {
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.8rem;
  color: white;
  margin-top: 1px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 7px 0;
}
#mobileCurrSongControls .skip.disabled,
#mobileCurrSongControls .fa-pause.disabled {
  color: lightgrey;
}
