#moodsOpener .curr-mood {
  display: flex;
  align-items: baseline;
}
#moodsOpener .curr-mood div {
  display: inline-block;
  text-decoration: underline;
  border-radius: 4px;
  height: 21px;
  top: 1px;
  position: relative;
  margin: 2px 3px;
}
#moodsOpener .curr-mood div span {
  position: relative;
  bottom: 1px;
}
#moodsOpener .curr-mood div:hover {
  transition-duration: 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.mood {
  color: #f5f59b;
}
.mood.bouncy,
.mood.in_love,
.mood.energized,
.mood.loved,
.mood.jolly,
.mood.awesome,
.mood.metal {
  color: #7cea7b;
}
.mood.amused,
.mood.crazy,
.mood.high,
.mood.happy,
.mood.rejuvenated,
.mood.pleased,
.mood.naughty,
.mood.nostalgic {
  color: #b8fff3;
}
.mood.angry,
.mood.confused,
.mood.discontent,
.mood.sick,
.mood.stressed,
.mood.tired,
.mood.bluesy,
.mood.dark,
.mood.scared {
  color: #bed6fc;
}
.mood.exhausted,
.mood.lonely,
.mood.melancholy,
.mood.very_angry {
  color: #ffd4d4;
}
