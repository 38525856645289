@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#subscribeToUserBeatCasesPopup {
    .modal-header{
        padding-bottom: 10px;
    }
    .modal-body{
        padding: 0;

        .spinnerHolder {
            text-align: center;
            padding-bottom: 17px;
        }

        #explanationTxt{
            text-align: center;
            margin: 0 50px;
            small{
                font-size: 1rem;
            }
            a{
                color: @color0_dark;
            }
        }
        .listHolder{
            border-radius: @spacing;
        }

        .ctaButton {
            text-align: center;
            padding: 15px;
        }
    }
}
