#feedDrop {
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
}
#feedDrop .feedItem {
  margin-bottom: 0;
}
#feedDrop #dropArea {
  margin: 0 0 20px;
  padding: 0;
  border: none;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 0;
}
#feedDrop .repliesHolder {
  padding-left: 40px;
}
