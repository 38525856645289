#chatInputSuggestedUsersHolder {
  position: absolute;
  top: 31px;
  left: 0;
  background-color: #efefef;
  width: 100%;
  z-index: 1;
  max-height: 80px;
}
#chatInputSuggestedUsersHolder .suggestedUser {
  padding: 4px 0 4px 5px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}
#chatInputSuggestedUsersHolder .suggestedUser img {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
#chatInputSuggestedUsersHolder .suggestedUser.selected {
  font-weight: 100;
  background-color: white;
}
