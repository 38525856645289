@staticBaseUrl: "https://cdn.beatsense.com/public";
@import "variables";

#jammingPartners{
    background-color: white;
    h3{
        margin: 0px;
        text-align: center;
        padding: 25px;
        .biggerText;
    }
    .resultsHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        .paginationIcon {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            color: @color0;
            padding: 5px;
            height: 80px;
            font-size: 4rem;
            text-shadow: 3px 2px @color2;
        }

        .listHolder {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            width: 1450px;
            .partnerBox {
                display: flex;
                margin-bottom: 30px;
                margin-right: 20px;
                &:hover{
                    text-decoration: none;
                }
                .userImage{
                    margin-right: 10px;
                    img {
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;
                    }
                }
                > div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: left;
                    > div {
                        display: flex;
                        .mediumText;
                        span{
                            margin-right: 5px;
                        }
                        a{
                            color: @color0_dark;
                            max-width: 114px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;
                        }
                        > div{
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 120px;
                            color: @color0_dark;
                            font-weight: bold;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                    .roomTags{
                        .smallText;
                        color: @grayText;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 200px;
                        display: inline;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
