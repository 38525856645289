@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../less/variables";
@import (reference) "../../../less/general";

#userProfile {
    padding: 18px 20px;
    height: 100vh;
    position: relative;
    background-color: #f8f8f8;
    .userProfileHeader{
        display: flex;
        margin-bottom: 15px;
        article{
			display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            .iconsHolder{
                position: absolute;
                top: 10px; right: 0px;
                > div {
                     > div {
                         display: flex;
                         justify-content: flex-end;
                         margin-bottom: 6px;
                         .profileAction {
                             display: flex;
                             color: white;
                             margin-right: 7px;
                             background-color: @color0;
                             border-radius: 50%;
                             width: 30px;
                             height: 30px;
                             cursor: pointer;
                             padding-top: 1px;
                             .flex;
                             .smallText;

                             &.disabled {
                                 background-color: #9b9898;
                                 cursor: default;
                             }

                             &.fa-envelope {
                                 background-color: @color2;
                             }

                             &.donateBeats {
                                 margin-right: 7px;
                                 cursor: pointer;

                                 .donationAmount {
                                     .smallText;
                                 }

                                 .fa-bolt {
                                     margin-left: 2px;
                                 }

                                 &.admin {
                                     color: red;

                                     .fa-bolt {
                                         color: red;
                                     }
                                 }
                             }
                         }
                     }
                }
            }
            //.reportUser{
            //    position: absolute;
            //    top: 1px;
            //    left: 2px;
            //    color: lightgray;
            //    .smallerText;
            //}
        }
    }

    .userProfileBody{
        display: flex;
        flex-direction: column;
        padding-bottom: 0px !important;
    }

    .userProfileBody > section, #musicTags{
        padding: 0 10px 10px;
        border-bottom: 10px;
        background-color: @bgColor_lighter;
        margin-bottom: 10px;
     }


    #profileFeed{
        padding-bottom: 25px;
    }

    #loadingProfile {
        text-align: center;
        width: 100%;
        color: @grayText;
        margin-top: 16px;
        font-size: 50px;
    }

    #userModalBanning{
        position: absolute;
        top: 52px;
        button{
            background-color: red;
            padding: 0px 8px 4px;
            border-radius: 20px;
        }
    }
    #userData{
        h3 {
            display: flex;
            .normalText;
            align-items: center;
            > * .countryIcon{
                margin-right: 5px;
                position: relative;
                bottom: -1px;
            }
            .userName{
                font-weight: 100;
                color: @color2;
                margin-right: 10px;
                .bigText;
            }

            .icons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 6px;

                i{
                    .smallText;
                    margin-top: 4px;
                    color: @color0_transparent_more;
                }

                .adminData {
                    color: black;
                }

                .userBanned {
                    color: red;
                }
            }
        }

        .prevUserName {
            position: relative;
            top: -5px;
            color: gray;
            .smallText;
        }

        #follows{
            .smallText;
            > span{
                margin-right: @spacing;
                b{
                    font-weight: 100;
                }
            }
        }

        #rank{
            color: @color0_dark;
            margin-top: 6px;
            .smallText;
            &:hover{
                text-decoration: underline;
            }
            a{
                text-decoration: none;
                .smallText
                strong{
                    text-decoration: underline;
                }
            }
            i.fa-star{
                color: @color2;
                margin-right: 2px;
            }
        }
    }
    .modalRooms{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        line-height: 24px;
        .modalRoom {
            display: flex;
            align-items: baseline;
            .roomName{
                color: @color0_dark;
                max-width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: block;
            }
            &:hover {
                text-decoration: none;
                .roomName{
                    text-decoration: underline;
                }
            }

            .fa-lock {
                margin-right: 5px;
            }
        }
    }
    .roomName {
        max-width: 366px;
        display: flex;
        align-items: center;
        i {
            color: @grayText;
            margin-right: 4px;
            .smallerText;
        }
    }
    .score {
        color: gray;
        .smallText;
        margin-left: 5px;
    }

    .userProfile_label {
        margin: 15px 0 10px;
        width: 100%;
        font-weight: bold;
        color: @grayText;
        i{
            position: relative;
            top: 1px;
            margin-right: 5px;
        }
        .flex;
        a {
            text-decoration: underline;
        }
        strong {
            font-weight: 100;
        }
    }

    #feed header{
        display: none;
    }

    #noDrops{
        width: 100%;
        text-align: center;
        color: gray;
    }

    #onlinePresence{
        #imOnline{
            > i{
                color: @onlineGreen;
                .smallText;
                position: relative;
                bottom: 1px;
            }
        }
        .otherPlatform{
            margin-right: 4px;
            .mediumText;
        }
    }

    #musicTags{
        background-color: @bgColor_lighter;
        padding: 8px;
        line-height: 22px;
        #since{
            .logoFont;
            .mediumText;
            color: @grayText;
            text-align: center;
        }
        #tagsHolder{
            text-align: center;
            margin: 2px 0;
            .tag{
                white-space: nowrap;
                color:@color2;
                display: inline-block;
            }
        }
    }

    .social-link{
        &:hover {
            text-decoration: none;
        }
    }

    .user-image-holder {
        margin-right: 10px;
        img {
            border-radius: 50%;
            height: 100px;
            width: 100px;
            border: 4px solid @bgColor_lighter;
        }
    }

    #featuredSongs{
        text-align: center;
        padding: 2px 6px 3px 2px;
        p{
            .fa-question-circle{
                bottom: 1px;
                .smallText;
            }
            a{
                color: @color0_dark;
                text-decoration: underline;
                cursor: pointer;
                font-weight: normal;
            }
        }
        .zebraList{
            transition: opacity 1s ease-out;
            opacity: 0;
            height: 0;
            overflow: hidden;
        }
        .featuredSongOpened{
            opacity: 1;
            height: auto;
        }
    }

    #userSoundtracks{
        label{
            margin-bottom: 15px;
        }
        .soundtrack{
            padding: 2px 6px 3px 2px;
            display: flex;
            align-items: center;
            .roomName{
                margin-right: 3px;
            }
            i{
                line-height: 20px;
                .smallerText;
            }
            img{
                border-radius: 50%;
                width: 40px;
                height: 40px;
                margin-right: 3px;
            }
        }
    }

    #madeMyDaySong{
        text-align: center;
        a{
            .flex;
            white-space: pre-wrap;
            font-size: 1.1rem;
            background-color: #fff7cc;
            padding: 8px 20px;
            border-radius: @borderRadius;
            img{
                border-radius: @borderRadius;
                margin-right: 15px;
            }
        }
    }

    #adminSection {
        position: absolute;
        z-index: 1000;
        left: 20px;
        top: 108px;

        .userData {
            background-color: lightgrey;
            padding: 3px 20px 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
        }

    }

    .userNotFound {
        text-align: center;
        margin-top: 30px;
        font-size: 1.5em;
        font-weight: bold;
    }
}

.inRoom{
    .userProfileBody > section, #musicTags{
        background-color: white !important;
     }
}