#postFavoritePopup {
  text-align: center;
}
#postFavoritePopup p i,
#postFavoritePopup p a {
  color: #2196F3;
}
#postFavoritePopup p i {
  font-size: 1.2rem;
}
#postFavoritePopup p a {
  text-decoration: underline;
}
#postFavoritePopup .buttonsHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#postFavoritePopup .buttonsHolder button {
  margin: 10px 10px;
  padding-top: 5px;
  padding-bottom: 8px;
  width: 100px;
}
