@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";
@import (reference) "../../../../less/general";

#togglePrivacyPopup {
  .featuresList {
    list-style-type: disc;
    padding-left: 13px;
    max-width: 500px;
    li{
      margin-bottom: 6px;
      span{
        color: gray;
        .smallText;
      }
    }
  }
  footer{
    margin-top: 5px;
    text-align: center;
  }
}
