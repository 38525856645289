@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.leveledRoomBadge {
    color: white;
    .smallerText;
    border-radius: @borderRadius;
    display: inline;
    padding: 0px 8px 1px;
    margin-left: 5px;
    font-weight: normal;
    cursor: default;
    position: relative;
    bottom: 2px;
    &.roomLevel_2{
        background-color: @beats;
    }
    &.roomLevel_3{
        background-color: #a1944d;
    }
    &.roomLevel_4{
        background-color: black;
    }
    &.disabled {
        background-color: gray;
        .clickable
    }
}