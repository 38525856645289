@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../less/variables';

.subscriptionBattery{
  line-height: 0.7;
  text-align: center;
  .text {
    font-size: 0.8em;
  }

  .icon {
    font-size: 1.4em;
    &.fa-battery-empty{
      color: @textColor;
    }

    &.fa-battery-quarter{
      color: @red2;
    }

    &.fa-battery-half{
      color: @color2;
    }

    &.fa-battery-three-quarters{
      color: @color0;
    }

    &.fa-battery-full{
      color: @green3;
    }
  }
}

.inRoom{
  .subscriptionBattery{
    .icon {
      &.fa-battery-empty {
        color: white;
      }
    }
  }
}