@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../less/variables';

#subscribableCasesList{
    .subscribableCase{
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 14px 9px;
        > div {
            display: flex;
            width: 100%;
            justify-content: space-between;
            cursor: default;
            > div {
                text-align: left;
                display: flex;
                align-items: center;
                img{
                    height: 45px;
                    width: 45px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                > div{
                    display: flex;
                    text-align: left;
                    .mediumText;
                    flex-direction: column;
                    >div{
                        display: flex;
                        margin-bottom: 2px;
                    }
                }
            }
            button{
                font-weight: normal;
                padding: 1px 11px 3px;
                height: 25px;
                &.subscribed{
                    background-color: @bgColor2;
                }
                span{
                    display: flex;
                    align-items: center;
                }
            }
            .stats{
                margin: 0 8px;
                font-weight: normal;
                font-size: 1rem;
            }
            b{
                text-overflow: ellipsis;
                max-width: 300px;
                overflow: hidden;
                white-space: nowrap;
                display: inline-block;
            }
            .caseOwner{
                margin-left: 3px;
                a{
                    color: @color0_dark;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            span{
                font-weight: normal;
                position: relative;
            }
        }
        p{
            margin: 1px 0;
            color: #666666;
            font-weight: normal;
            font-size: 1rem !important;
        }
    }
}
