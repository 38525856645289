#myPrivilegedFeatureModal .modal-body {
  font-size: 1.1rem;
}
#myPrivilegedFeatureModal .modal-body h2 {
  font-weight: bold;
}
#myPrivilegedFeatureModal .modal-body .transferMsg {
  color: blue;
}
#myPrivilegedFeatureModal .buttonsHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 22px 0 10px;
}
#myPrivilegedFeatureModal .buttonsHolder > div {
  font-size: 0.9rem;
}
#myPrivilegedFeatureModal .buttonsHolder > button {
  margin: 0 10px;
}
#myPrivilegedFeatureModal .buttonsHolder > button .fa-mug-hot {
  margin-left: 3px;
  position: relative;
  bottom: 1px;
}
#myPrivilegedFeatureModal .buttonsHolder > button.glow-on-hover {
  background-color: #53bd87;
}
