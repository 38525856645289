@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../less/variables';

#mobileCurrSongControls{
  background-color: @glassBg_darker;
  .giantText;
  color: white;
  margin-top: 1px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 7px 0;
  .skip.disabled, .fa-pause.disabled {
    color: lightgrey;
  }
}