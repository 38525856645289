@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../less/variables';

#mobileRightMenu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  color: @textColor;
  height: 100vh;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
  .mediumText;
  i{
    .giantText;
  }
  section{
    > div{
      padding: 13px 10px;
      text-align: center;
    }
  }

  #profile {
    padding: 10px;
    text-align: center;
    background-color: @bgColor;
    max-width: 73px;
    img.user-image {
      width: 35px;
      height: 35px;
    }
  }
  .subscriptionBattery{
    .icon {
      &.fa-battery-empty {
        color: @textColor;
      }
    }
    .subscription {
      .subscriptionBattery {
        text-align: left;
      }
    }
  }
  #inboxOpener{
    display: inline-flex;
  }
  #userAlertsOpener{
    margin: 0;
  }
}