@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../../less/variables';

.pollBuilder {
	text-align: left;

	.pollOption {
		display: flex;
		margin-bottom: 3px;
		align-items: center;
		label{
			margin-right: 12px;
			display: flex;
			width: 61px;
			white-space: nowrap;
			font-weight: bold;
			.normalText !important;
		}

		input{
			width: 220px;
			border: 1px solid #979797;
		}

		.fa-trash-alt{
			color: #979797;
			.smallText;
			margin-left: 5px;
			position: relative;
			top: 1px;
			cursor: pointer;
		}
	}

	.link{
		margin: 8px 0;
		a{
			color: @color0_dark;
			.smallText;
		}
	}
}
