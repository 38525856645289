.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*Animations*/
.noblink {
  -webkit-animation-iteration-count: 0 !important;
  animation-iteration-count: 0 !important;
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.flip {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  /*IE*/
  filter: fliph;
  /*IE*/
}
.clickable {
  cursor: pointer;
}
.notclickable {
  text-decoration: none !important;
  cursor: default !important;
}
.unlink {
  text-decoration: none !important;
}
.grid > * {
  width: 20%;
  display: inline-block;
}
@media screen and (max-width: 1600px) {
  .grid > * {
    width: 25%;
  }
}
@media screen and (max-width: 1250px) {
  .grid > * {
    width: 33%;
  }
}
@media screen and (max-width: 950px) {
  .grid > * {
    width: 50%;
  }
}
.giantText {
  font-size: 1.8rem;
}
.biggerText {
  font-size: 1.6rem;
}
.bigText {
  font-size: 1.4rem;
}
.mediumText {
  font-size: 1.2rem;
}
.normalText {
  font-size: 1.1rem;
}
.semiSmallText {
  font-size: 0.95rem;
}
.smallText {
  font-size: 0.9rem;
}
.smallerText {
  font-size: 0.8rem;
}
.tinyText {
  font-size: 0.75rem;
}
.smallestText {
  font-size: 0.65rem;
}
#jammingPartners {
  background-color: white;
}
#jammingPartners h3 {
  margin: 0px;
  text-align: center;
  padding: 25px;
  font-size: 1.6rem;
}
#jammingPartners .resultsHolder {
  display: flex;
  align-items: center;
  justify-content: center;
}
#jammingPartners .resultsHolder .paginationIcon {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  color: #2196F3;
  padding: 5px;
  height: 80px;
  font-size: 4rem;
  text-shadow: 3px 2px #f32196;
}
#jammingPartners .resultsHolder .listHolder {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 1450px;
}
#jammingPartners .resultsHolder .listHolder .partnerBox {
  display: flex;
  margin-bottom: 30px;
  margin-right: 20px;
}
#jammingPartners .resultsHolder .listHolder .partnerBox:hover {
  text-decoration: none;
}
#jammingPartners .resultsHolder .listHolder .partnerBox .userImage {
  margin-right: 10px;
}
#jammingPartners .resultsHolder .listHolder .partnerBox .userImage img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
#jammingPartners .resultsHolder .listHolder .partnerBox > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
#jammingPartners .resultsHolder .listHolder .partnerBox > div > div {
  display: flex;
  font-size: 1.2rem;
}
#jammingPartners .resultsHolder .listHolder .partnerBox > div > div span {
  margin-right: 5px;
}
#jammingPartners .resultsHolder .listHolder .partnerBox > div > div a {
  color: #0c7cd5;
  max-width: 114px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
#jammingPartners .resultsHolder .listHolder .partnerBox > div > div > div {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 120px;
  color: #0c7cd5;
  font-weight: bold;
}
#jammingPartners .resultsHolder .listHolder .partnerBox > div > div > div:hover {
  text-decoration: underline;
}
#jammingPartners .resultsHolder .listHolder .partnerBox > div .roomTags {
  font-size: 0.9rem;
  color: #6f6f6f;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  display: inline;
  white-space: nowrap;
}
