#dropArea {
  background-color: white;
  display: flex;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #e8e8e8;
  margin-top: 5px;
}
#dropArea #dropAreaProfileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
#dropArea #dropInput {
  width: 471px;
  padding-left: 12px;
}
#dropArea #dropInput #dropTypeSelection {
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
}
#dropArea #dropInput #dropTypeSelection label {
  font-size: inherit;
  margin-right: 5px;
}
#dropArea #dropInput #dropContent {
  cursor: text;
  font-size: 1.1rem;
  min-height: 48px;
  padding-top: 12px;
  border-bottom: #eaeaea 1px solid;
  margin-bottom: 12px;
}
#dropArea #dropInput #dropContent:empty::before {
  content: attr(data-placeholder);
  color: gray;
}
#dropArea #dropInput footer {
  color: #2196F3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#dropArea #dropInput footer button {
  font-size: 1.2rem;
  border-radius: 50px;
  padding: 5px 15px 8px;
}
#dropArea #dropInput footer button.disabledBtn {
  opacity: 0.5;
  cursor: default;
}
#dropArea #dropInput footer #enrichments {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
#dropArea #dropInput footer #enrichments a {
  margin-right: 20px;
  line-height: 12px;
  color: black;
}
#dropArea #dropInput footer #enrichments a:hover {
  text-decoration: none;
}
#dropArea #dropInput footer #enrichments a.disabled {
  color: lightgrey;
}
#dropArea #dropInput footer #enrichments #dropImg {
  font-size: 1.6rem;
  position: relative;
  top: 1px;
}
#dropArea #dropInput footer #enrichments #dropGif {
  font-size: 0.8rem;
  font-weight: bold;
  padding: 2px 2px 3px;
  border: 2px solid black;
  line-height: 10px;
}
#dropArea #dropPollBuilder {
  width: 100%;
  position: relative;
}
#dropArea #dropPollBuilder #dropPollBtnRemove {
  position: absolute;
  right: 0px;
  z-index: 11;
}
#dropArea #dropAttachment {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
#dropArea #dropAttachment #attachmentBlocker {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
#dropArea #dropAttachment #dropAttachmentBtnRemove {
  position: absolute;
  right: 0px;
  z-index: 11;
}
#dropArea #dropAttachment #dropAttachmentContent {
  text-align: center;
}
#dropArea #dropAttachment #dropAttachmentContent .feedDropItemImage img {
  max-width: 350px;
  max-height: 500px;
}
