#feedDropPopup .modal-header {
  margin-bottom: 0;
}
#feedDropPopup .modal-body {
  padding: 0px;
}
#feedDropPopup .modal-body #dropArea {
  margin-bottom: 20px;
  border: none;
}
#feedDropPopup .btn-close {
  font-size: 1.2rem;
}
