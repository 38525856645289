#profileWatchesPopup {
  text-align: center;
}
#profileWatchesPopup .modal-body {
  padding: 0px 10px 20px;
}
#profileWatchesPopup .modal-body h3 {
  font-weight: normal;
}
#profileWatchesPopup .modal-body h3 b {
  font-weight: 100;
}
#profileWatchesPopup .modal-body h4 {
  font-size: 1.2rem;
  font-weight: normal;
  padding: 12px;
}
#profileWatchesPopup .modal-body button {
  font-size: 1.2rem;
}
#profileWatchesPopup .watchersList li {
  padding: 8px 6px 10px;
  font-size: 1.2rem;
}
#profileWatchesPopup .watchersList li .userNameAndDate {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  bottom: 2px;
}
#profileWatchesPopup .watchersList li .userNameAndDate .userName {
  color: #0c7cd5;
}
#profileWatchesPopup .watchersList li .userNameAndDate .watchDate {
  color: gray;
  font-size: 0.9rem;
}
#profileWatchesPopup .watchersList li .userImageHolder img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 8px;
}
