@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.songOpinion{
    span{
        white-space: break-spaces;
    }
    .doNotPlay{
        color: red;
    }
    .neutral{
        color: blue;
    }
    .meh{
        color: @red2;
    }
    .nothingExiting{
        color: blue;
    }
    .safeBet{
        color: purple;
    }
    .pettyGood{
        color: @green4_dark;
    }
    .fuckingAwesome{
        color: @green;

    }
}
