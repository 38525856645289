@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../less/general";
@import (reference) '../../../less/variables';

#chats {
    .chat {
        pointer-events: auto;
        margin-right: 10px;
        width: 320px;

        .chatHeader {
            position: relative;
            background-color: @glassBg_evenDarker;
            color: white;
            padding: 3px 2px 5px 5px;
            width: 320px;
            .clickable;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 4px 4px -4px;

            > *{
                display: flex;
                align-items: center;
                > *{
                    display: flex;
                    align-items: center;
                    margin-right: 13px;
                }
            }
            &.focus {
                background-color: #efefef;
            }

            .userImage {
                height: 25px;
                width: 25px;
                border-radius: 50%;
            }

            .userName {
                margin-left: 5px;
                display: inherit;
            }

            .unreads {
                background-color: @green4_dark;
                width: 16px;
                height: 16px;
                border-radius: 50px;
                .flex;
                color: #fff;
                .smallerText;
                font-weight: bold;
                padding-left: 1px;
                position: relative;
                top: 1px;
            }

            .btnClose {
             .clickable;
            }

            .fa-shield-alt{
                opacity: 0.4;
            }
            .blocked{
                color: @red2;
                .mediumText;
                opacity: 1;
            }
        }

        .chatBody {
            height: 256px;
            position: relative;
            background-color: rgba(0, 0, 0,0.5);
            .messagesHolder {
                height: 225px;
                position: relative;
                .scrollArea {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    max-height: 225px;
                    width: 100%;
                    .chatMessageRow{
                        background-color:rgba(0, 0, 0, 0.9);
                    }
                    :nth-child(even) .chatMessageRow{
                        background-color:@glassBg_evenDarker;
                    }
                }
            }

            .loader {
                width: 100%;
                height: 100%;
                border: none;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .5;
                text-align: center;

                > i {
                    font-size: 6em;
                    margin-top: 73px;
                    color: #fff;
                }
            }
        }

        &.mobile {
            width: 100vw;
            font-size: 16px;
            margin-right: 0px;

            .chatBody, .chatBody .messagesHolder{
                height: auto !important;
            }

            .chatBody{
                background-color: #04527e;
                .messagesHolder {
                    .scrollArea{
                        height: @mobileChatHeight !important;
                        max-height: initial !important;
                        width: 100%;
                        overflow-y: auto !important;
                        position: relative;
                        bottom: auto;
                        left: auto;
                    }
                }
            }
            .chatInputHolder{
                input{
                    width: 100%;
                    height: 40px;
                    border-bottom: 1px solid lightgrey;
                }
                .chatEmojisOpener {
                    right: 45px;
                    font-size: 18px;
                    top: 3px;
                }
                .gifsOpener{
                    right: 8px;
                    font-size: 18px;
                    padding: 4px;
                    top: 3px;
                }
            }
            .chatHeader{
                width: inherit;
            }
        }
    }
}