#mobileRightMenu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  color: #404040;
  height: 100vh;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
}
#mobileRightMenu i {
  font-size: 1.8rem;
}
#mobileRightMenu section > div {
  padding: 13px 10px;
  text-align: center;
}
#mobileRightMenu #profile {
  padding: 10px;
  text-align: center;
  background-color: #e6e6e6;
  max-width: 73px;
}
#mobileRightMenu #profile img.user-image {
  width: 35px;
  height: 35px;
}
#mobileRightMenu .subscriptionBattery .icon.fa-battery-empty {
  color: #404040;
}
#mobileRightMenu .subscriptionBattery .subscription .subscriptionBattery {
  text-align: left;
}
#mobileRightMenu #inboxOpener {
  display: inline-flex;
}
#mobileRightMenu #userAlertsOpener {
  margin: 0;
}
