#roomLevelPopup #roomLevelFeatures .roomLevelFeature {
  margin-bottom: 10px;
}
#roomLevelPopup #roomLevelFeatures .roomLevelFeature .fa-check {
  margin-right: 5px;
}
#roomLevelPopup #buttonsArea {
  margin-top: 15px;
  text-align: center;
}
