@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#roomAccessRequestPopup {
	text-align: center;
	.modal-body{
		.mediumText;
		section{
			margin-bottom: 15px;
			a{
				color: @color0_dark;
			}
		}
		#notEnoughBeats{
			.smallText;
			color: @red;
			margin-top: 10px;
			a{
				color: @textColor;
				text-decoration: underline;
			}
		}
	}
}
