#userAlertsHolder {
  position: fixed;
  top: 48px;
  right: calc((100vw - 1150px) / 2);
  z-index: 2;
}
#userAlertsHolder .ps-scrollbar-y-rail {
  display: none;
}
#userAlertsHolder .loaderHolder {
  right: 43px;
  position: absolute;
}
#userAlertsHolder .loaderHolder > i {
  margin-top: 7px;
}
#userAlertsHolder .noAlerts {
  text-align: center;
  color: #404040;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
}
#userAlertsHolder .list {
  max-height: 76vh;
  margin-bottom: 2px;
}
#userAlertsHolder .userAlertHolder {
  position: relative;
  background-color: white;
  padding: 12px;
  display: flex;
  min-width: 200px;
  margin-bottom: 2px;
  color: #404040;
}
#userAlertsHolder .userAlertHolder a {
  cursor: pointer;
}
#userAlertsHolder .userAlertHolder a.btn {
  margin-top: 5px;
  padding: 3px 10px 6px;
}
#userAlertsHolder .userAlertHolder.unread {
  background-color: #e6e6e6;
}
#userAlertsHolder .userAlertHolder .iconWrapper,
#userAlertsHolder .userAlertHolder .imageWrapper {
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px 0 2px;
}
#userAlertsHolder .userAlertHolder .iconWrapper i,
#userAlertsHolder .userAlertHolder .imageWrapper i {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 0px;
  font-size: 25px;
  background-color: #e6e6e6;
  border-radius: 50%;
  padding: 10px 8px;
  width: 40px;
  height: 40px;
}
#userAlertsHolder .userAlertHolder .imageWrapper img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
#userAlertsHolder .userAlertHolder .contentWrapper {
  line-height: 20px;
}
#userAlertsHolder .userAlertHolder .contentWrapper .alertMessage {
  white-space: normal;
  max-width: 300px;
  padding-right: 10px;
}
#userAlertsHolder .userAlertHolder .contentWrapper .alertMessage a {
  color: #f32196;
}
#userAlertsHolder .userAlertHolder .contentWrapper .alertAddedAt {
  opacity: 0.7;
  font-size: 0.9rem;
}
#userAlertsHolder .userAlertHolder .contentWrapper .actionsHolder {
  display: flex;
  margin-bottom: 1px;
}
#userAlertsHolder .userAlertHolder .contentWrapper .actionsHolder :not(:last-child) {
  margin-right: 5px;
}
#userAlertsHolder .userAlertHolder .contentWrapper .actionsHolder button {
  padding: 3px 10px 6px;
  white-space: nowrap;
  margin-top: 5px;
}
#userAlertsHolder .userAlertHolder .loaderHolder {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}
#userAlertsHolder .userAlertHolder .loaderHolder .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}
#userAlertsHolder .userAlertHolder .loaderHolder > i {
  margin-top: 14px;
  color: #fff;
}
#userAlertsHolder .userAlertHolder .btnDelete {
  position: absolute;
  top: 5px;
  right: 9px;
  font-size: 0.9rem;
}
#userAlertsHolder #clearAll {
  background-color: #2196F3;
  display: block;
  color: white;
  text-align: center;
  padding: 3px 0 4px;
  font-weight: bold;
  width: 100%;
  font-size: 0.9rem;
}
#userAlertsHolder #clearAll:hover {
  text-decoration: none;
  background-color: #0c7cd5;
}
.inRoom #userAlertsHolder {
  right: 5px;
}
