@staticBaseUrl: "https://cdn.beatsense.com/public";
.copyPasteInput {
  position: relative;

  > input {
    width: 100%;
    padding-right: 20px;
  }

  .copy {
    position: absolute;
    top: 4px;
    right: 5px;
  }
}