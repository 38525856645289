@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.usersModal {
  .modal-content{
    max-width: 500px !important;
  }
}

#usersPopup {
  .modal-body {
    .searchHolder {
      display: flex;
      justify-content: stretch;
      align-items: center;
      .homeColumnSearch{
        flex-grow: 2;
        margin-right: 10px;
      }
    }

    .listHolder {
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      #user{
        padding-right: 20px;
        > a{
          .details{
            width: 150px;
          }
        }
      }
    }
  }
}
