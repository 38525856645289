.roomTileHolder {
  text-align: left;
  position: relative;
  overflow: hidden;
  transition-property: box-shadow;
  transition-duration: 0.2s;
}
.roomTileHolder .overlay {
  transition-duration: 0.4s;
  background-color: #f32196;
  opacity: 0.4;
  border-radius: 5px;
}
.roomTileHolder:hover {
  box-shadow: 5px 1px 6px rgba(25, 25, 34, 0.26);
}
.roomTileHolder:hover .overlay {
  background-color: transparent;
}
.roomTileHolder .tooltip {
  font-size: 0.75rem;
}
.roomTileHolder .room-header {
  color: #fff;
  padding: 6px 10px;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0px;
  display: flex;
  justify-content: space-between;
}
.roomTileHolder .room-header .add-to-favorites {
  cursor: pointer;
}
.roomTileHolder .room-header .num-of-online-users-in-room {
  font-weight: 100;
}
.roomTileHolder .holder {
  height: 100%;
  width: 250px;
}
.roomTileHolder:hover a {
  text-decoration: none;
}
.roomTileHolder:hover .room-image .tilePlay {
  animation: myanim 1s infinite;
}
@keyframes myanim {
  50% {
    padding-bottom: 30px;
  }
}
.roomTileHolder .tileFloatIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  text-align: center;
}
.roomTileHolder .overlay {
  background-color: #333;
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.roomTileHolder .room-image {
  position: relative;
  height: 140px;
  width: 250px;
}
.roomTileHolder .room-image .overlayWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomTileHolder .room-image .overlayWrapper .fa-lock {
  color: #fff;
  font-size: 3em;
  opacity: 0.8;
  padding-bottom: 42px;
}
.roomTileHolder .room-image .overlayWrapper .entranceFeeBtn {
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  color: white;
  line-height: 26px;
  padding-bottom: 33px;
}
.roomTileHolder .room-image .overlayWrapper .entranceFeeBtn .fa-key {
  font-size: 1.4rem;
}
.roomTileHolder .room-image .tilePlayWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomTileHolder .room-image .tilePlayWrapper .tilePlay {
  display: flex;
  align-items: center;
  font-size: 3em;
  justify-content: center;
  padding-bottom: 42px;
  color: rgba(255, 255, 255, 0.8);
}
.roomTileHolder .room-image .tileThumbnail {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.roomTileHolder .room-image .overlayWrapper i.premium,
.roomTileHolder .room-image .tilePlayWrapper i.premium {
  color: #2196F3;
}
.roomTileHolder .roomTile_defaultImg {
  height: 270px;
}
.roomTileHolder .curr-song-name {
  color: white;
  height: 25px;
  background-color: rgba(249, 44, 243, 0.9);
  padding: 2px 10px 0px;
  position: relative;
}
.roomTileHolder .curr-song-name b {
  font-weight: 100;
}
.roomTileHolder .roomTile_songName {
  display: block;
}
.roomTileHolder .roomTile_songName:hover {
  text-decoration: none;
}
.roomTileHolder .tileBottom .usersEllipsis {
  color: white;
  font-weight: bold;
  position: relative;
  top: 10px;
}
.roomTileHolder .tileBottom .room-name {
  padding: 5px 8px;
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  justify-content: space-between;
}
.roomTileHolder .tileBottom .room-name > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.roomTileHolder .tileBottom .room-name > div .nameAndLock {
  display: flex;
  align-items: center;
  font-weight: bold;
  max-width: 100px;
}
.roomTileHolder .tileBottom .room-name > div .nameAndLock .privateRoomLock {
  margin-right: 5px;
  font-size: 0.9rem;
}
.roomTileHolder .tileBottom .room-name .user-image {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.roomTileHolder .tileBottom .room-name h3 {
  font-weight: normal;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0 0 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.roomTileHolder .tileBottom .room-name h3 a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.roomTileHolder .tileBottom .room-name h3 i {
  margin-right: 5px;
}
.roomTileHolder .tileBottom .room-name h3 .name-holder {
  display: block;
  line-height: 20px;
}
.roomTileHolder .tileBottom .room-name .room-tags {
  font-size: 0.9rem;
  color: #f32196;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.roomTileHolder .tileBottom .room-name .room-tags a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.room-users-images {
  padding: 0 5px;
  display: flex;
  position: absolute;
  top: 61px;
  left: 3px;
}
.room-users-images .room-user-image {
  margin-right: 5px;
  margin-bottom: 5px;
}
.room-users-images .room-user-image img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: -8px;
}
.room-users-images .room-user-image span {
  color: white;
  font-weight: bold;
  font-size: 1.6rem;
  vertical-align: bottom;
}
