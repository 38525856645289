#myGifsModal .modal-body {
  padding-top: 20px;
  height: 592px;
  width: 620px;
}
#myGifsModal .modal-body > .fa-times {
  float: right;
  font-size: 1.2rem;
}
#myGifsModal .modal-body .gifsModalSearchBox {
  width: 100%;
  margin: 12px 0;
}
#myGifsModal .modal-body .gifsModalSearchBox > input,
#myGifsModal .modal-body .gifsModalSearchBox > i {
  font-size: 1.2rem;
}
#myGifsModal .modal-body .gifsModalSearchBox > input {
  height: 40px;
  width: 100%;
  max-width: initial;
}
#myGifsModal .modal-body .listHolder {
  display: flex;
  flex-flow: row wrap;
  max-height: 462px;
  overflow-y: auto;
}
#myGifsModal .modal-body .listHolder .gifItem {
  width: 116px;
  height: 116px;
  position: relative;
  cursor: pointer;
}
#myGifsModal .modal-body .listHolder .gifItem img {
  height: 100%;
  width: 100%;
}
#myGifsModal .modal-body .listHolder .gifItem .infoHolder {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
}
#myGifsModal .modal-body .listHolder .gifItem .infoHolder .bg,
#myGifsModal .modal-body .listHolder .gifItem .infoHolder .price,
#myGifsModal .modal-body .listHolder .gifItem .infoHolder .user {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#myGifsModal .modal-body .listHolder .gifItem .infoHolder .bg {
  background-color: #000;
  opacity: 0.5;
}
#myGifsModal .modal-body .listHolder .gifItem .infoHolder .price {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#myGifsModal .modal-body .listHolder .gifItem .infoHolder .price .txt {
  font-size: 1.4rem;
}
#myGifsModal .modal-body .listHolder .gifItem .infoHolder .price .amount {
  margin-left: 8px;
  position: relative;
  top: 1px;
  font-size: 1.2rem;
}
#myGifsModal .modal-body .listHolder .gifItem .infoHolder .user {
  top: auto;
  bottom: 0;
  color: #fff;
  height: auto;
  padding: 5px;
  font-size: 0.8rem;
}
#myGifsModal .modal-body .listHolder .gifItem:hover .infoHolder {
  display: block;
}
#myGifsModal .modal-body .poweredByHolder {
  margin-top: 5px;
  font-size: 0.75em;
  font-weight: bold;
}
#myGifsModal .modal-body .poweredByHolder > img {
  width: 60px;
}
