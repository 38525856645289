#beatzonePopup {
  min-width: 550px !important;
  position: relative;
}
#beatzonePopup .modal-body .error {
  font-size: 0.9rem;
}
#beatzonePopup .modal-body .my-row.upload-photo-buttons-holder,
#beatzonePopup .modal-body .my-row.error-and-notification {
  margin-top: 6px;
}
#beatzonePopup .modal-body .my-row.upload-photo-buttons-holder .notification,
#beatzonePopup .modal-body .my-row.error-and-notification .notification {
  color: #219820;
}
#beatzonePopup .modal-body .edit-user-modal-user-image-holder {
  position: relative;
  padding: 0px;
  margin-right: 15px;
}
#beatzonePopup .modal-body .edit-user-modal-user-image-holder .user-photo-options {
  position: absolute;
  top: -2px;
  z-index: 3;
}
#beatzonePopup .modal-body .edit-user-modal-user-image-holder .user-photo-options .fa-camera-retro {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 1.2rem;
  color: #2196F3;
}
#beatzonePopup .modal-body .edit-user-modal-user-image-holder .user-photo-options > .options-holder {
  background-color: white;
  padding: 5px 5px 8px 8px;
  width: 187px;
  font-size: 0.9rem;
  margin-top: 16px;
  color: black;
}
#beatzonePopup .modal-body .edit-user-modal-user-image-holder > img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
#beatzonePopup .modal-body .edit-user-modal-user-image-holder .userImageUploadSpinner {
  position: absolute;
  top: 20px;
  left: 26px;
  font-size: 2em;
}
#beatzonePopup .modal-body .upload-photo-buttons-holder {
  margin-top: 8px;
  font-size: 0.9rem;
}
#beatzonePopup .modal-body .upload-photo-buttons-holder .btn-use-fb-image {
  cursor: pointer;
}
#beatzonePopup .modal-body .upload-photo-buttons-holder .btn-use-fb-image:hover {
  text-decoration: underline;
}
#beatzonePopup .modal-body .upload-photo-buttons-holder .btn-use-fb-image i {
  margin-right: 1px;
}
#beatzonePopup .modal-body .upload-photo-buttons-holder .btn-upload-image a {
  color: #3D3C49 !important;
  text-decoration: underline;
}
#beatzonePopup .modal-body #edit-user-details-modal_top {
  display: flex;
  position: relative;
  margin-bottom: 15px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder > div .fa-pen {
  font-size: 1.2rem;
  color: #2196F3;
  margin-left: 5px;
  cursor: pointer;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #usernameAndCountry {
  display: flex;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #usernameAndCountry .countryHolder {
  margin-left: 15px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-name,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-email {
  position: relative;
  display: flex;
  align-items: baseline;
  height: 30px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-name b,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-email b {
  margin-right: 3px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-name #txtUsername,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-email #txtUsername,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-name #txtEmail,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-email #txtEmail {
  display: inline-block;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-name #txtUsername,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-email #txtUsername {
  width: 210px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-name #txtEmail,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-email #txtEmail {
  width: 247px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-name label,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-email label {
  margin-right: 2px;
  margin-bottom: 0px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-name span,
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder .my-row.user-email span {
  max-width: 280px;
  float: left;
  position: relative;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange {
  font-size: 0.9rem;
  display: flex;
  font-size: 0.8rem;
  margin-top: 12px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange .logout {
  margin-right: 10px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange .logout #logout-link {
  color: #f53c56;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange #change-password-group {
  margin-top: 5px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange #change-password-group > div {
  display: flex;
  white-space: nowrap;
  align-items: baseline;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange #change-password-group > div b {
  font-size: 1rem;
  margin-right: 4px;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange #change-password-group > div input {
  height: 28px;
  padding-left: 7px;
  font-size: 0.9rem;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange .change-password #change-password-link {
  cursor: pointer;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #logoutAndPasswordChange .change-password #change-password-link:hover {
  text-decoration: underline;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #privacy {
  display: flex;
  align-items: baseline;
}
#beatzonePopup .modal-body #edit-user-details-modal_top .user-details-holder #privacy span {
  font-size: 0.8rem;
  margin-left: 1px;
}
#beatzonePopup .modal-body #howPplSeeMyProfile {
  position: absolute;
  top: 2px;
  right: 32px;
}
#beatzonePopup .modal-body #profileWatchesOpener {
  position: absolute;
  top: 2px;
  right: 55px;
}
#beatzonePopup .modal-body .scrobble-holder {
  position: absolute;
  top: -3px;
  right: 0;
}
#beatzonePopup .modal-body .scrobble-holder > button {
  font-size: 0.9em !important;
  font-weight: normal !important;
  padding: 0px 5px 2px !important;
  background-color: #B00000;
}
#beatzonePopup .modal-body .scrobble-holder > button.startScrobbling {
  background-color: #AE6868;
}
#beatzonePopup .modal-body #beatZone_roomLists {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
}
#beatzonePopup .modal-body #beatZone_roomLists h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 14px;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room {
  padding: 5px 7px 7px;
  font-size: 1rem;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room .fa-lock {
  margin-right: 5px;
  font-size: 0.9rem;
  position: relative;
  top: 1px;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room .roomName {
  display: inline-block;
  max-width: 302px;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room .roomOpener {
  color: #3D3C49;
  max-width: 116px;
  display: inline-block;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room .roomScore,
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room .roomOpener {
  color: gray;
  margin-left: 5px;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room:nth-child(odd) {
  background-color: #e6e6e6;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room:nth-child(even) {
  background-color: #f8f8f8;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room:hover {
  background-color: #E3EEEE;
  text-decoration: none;
}
#beatzonePopup .modal-body #beatZone_roomLists .beatZone_roomLists_room:hover i {
  text-decoration: none;
}
#beatzonePopup .modal-body #beatZone_roomLists .rooms-tabs {
  position: relative;
}
#beatzonePopup .modal-body #beatZone_roomLists .rooms-tabs .tab {
  padding: 2px 14px 6px;
  width: auto;
  line-height: 27px;
  font-weight: bold;
}
#beatzonePopup .modal-body #beatZone_roomLists .rooms-tabs .tab.active {
  background-color: #e6e6e6;
}
#beatzonePopup .modal-body #beatZone_roomLists .rooms-tabs .globalScores {
  right: 0px;
  top: 6px;
  position: absolute;
  font-size: 0.9rem;
}
#beatzonePopup .modal-body #beatZone_roomLists .rooms-tabs .globalScores a {
  text-decoration: underline;
}
#beatzonePopup .modal-body #beatZone_roomLists .rooms-tabs .globalScores .fa-star {
  color: #f32196;
}
#beatzonePopup .modal-body #beatZone_roomLists #roomListsHolder {
  max-height: 171px;
  background-color: #f8f8f8;
}
#beatzonePopup .modal-body #beatZone_roomLists #roomListsHolder .my-row a:focus {
  color: #337ab7 !important;
}
#beatzonePopup .modal-body #beatZone_roomLists #roomListsHolder .my-row .noBeatRooms {
  font-size: 0.9rem;
  text-align: center;
  padding: 8px 17px !important;
  color: black;
}
#beatzonePopup .modal-body #beatZone_roomLists #roomListsHolder .my-row.my-favorites-beatrooms .beatZone_roomLists_room {
  position: relative;
}
#beatzonePopup .modal-body #beatZone_roomLists #roomListsHolder .my-row.my-favorites-beatrooms .beatZone_roomLists_room .btn-remove-from-favorites {
  position: absolute;
  right: 14px;
  padding: -2px 15px;
}
#beatzonePopup .modal-body #userSoundtracks {
  margin-top: 16px;
}
#beatzonePopup .modal-body #userSoundtracks label i {
  margin-right: 5px;
}
#beatzonePopup .modal-body #userSoundtracks .soundtrack,
#beatzonePopup .modal-body #userSoundtracks .featuredSong {
  display: flex;
  padding: 5px 7px 7px;
  justify-content: space-between;
  font-size: 1rem;
}
#beatzonePopup .modal-body #userSoundtracks .soundtrack i,
#beatzonePopup .modal-body #userSoundtracks .featuredSong i {
  line-height: 20px;
}
#beatzonePopup .modal-body #biographyHolder {
  margin-top: 15px;
}
#beatzonePopup .modal-body #biographyHolder #noBiography {
  cursor: pointer;
  color: gray;
}
#beatzonePopup .modal-body #biographyHolder .biography,
#beatzonePopup .modal-body #biographyHolder #noBiography {
  padding-left: 6px;
  font-size: 1rem;
}
#beatzonePopup .modal-body #biographyHolder .view {
  margin-top: 5px;
  background-color: #e6e6e6;
  border-radius: 25px;
}
#beatzonePopup .modal-body #biographyHolder .view:hover {
  background-color: #f8f8f8;
}
#beatzonePopup .modal-body #biographyHolder textarea {
  width: 100%;
  margin-top: 5px;
  height: 120px;
  padding: 5px 10px;
  margin-bottom: 5px;
}
#beatzonePopup .modal-body #biographyHolder .edit {
  text-align: right;
}
#beatzonePopup .modal-footer {
  padding: 12px 8px 27px;
  margin-top: 20px !important;
  text-align: center;
  background-color: #f8f8f8;
}
#beatzonePopup .modal-footer #beatsBalance {
  padding-bottom: 16px;
}
#beatzonePopup .modal-footer #beatsBalance i {
  margin-left: 1px;
  color: #79A2A1;
}
#beatzonePopup button {
  font-size: 0.95em;
  padding: 2px 15px 5px;
}
#beatzonePopup .adminIconsHolder {
  position: absolute;
  z-index: 4;
}
#beatzonePopup .adminIconsHolder .buttonsHolder {
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
#beatzonePopup .adminIconsHolder .buttonsHolder a {
  text-decoration: none;
}
#beatzonePopup .adminIconsHolder .impersonateForm {
  background-color: white;
  border: 1px solid;
  padding: 10px;
}
#beatzonePopup .modal-body {
  display: table;
  width: 100%;
}
#beatzonePopup .modal-body .upload-image-holder {
  padding-right: 0px;
}
#beatzonePopup .modal-body .upload-image-holder > img {
  max-width: 150px;
}
#beatzonePopup .modal-body .upload-image-holder .btn-upload-image {
  margin-top: 3px;
  cursor: pointer;
  font-size: 0.85em;
}
#beatzonePopup .modal-body .upload-image-holder .btn-upload-image:hover {
  text-decoration: underline;
}
#beatzonePopup .modal-body .upload-image-holder .buttons-holder {
  margin-top: 8px;
  font-size: 0.8em;
}
#beatzonePopup .modal-body .upload-image-holder .buttons-holder .btn-use-fb-image {
  cursor: pointer;
  font-size: 0.85em;
}
#beatzonePopup .modal-body .upload-image-holder .buttons-holder .btn-use-fb-image i {
  margin-right: 1px;
}
#beatzonePopup .modal-body .upload-image-holder .buttons-holder .btn-use-fb-image:hover {
  text-decoration: underline;
}
#beatzonePopup .modal-body #accountDeletion {
  color: #f53c56;
  font-size: 0.8rem;
  margin-top: 15px;
  display: inline-block;
  float: right;
}
