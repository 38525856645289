.usersModal .modal-content {
  max-width: 500px !important;
}
#usersPopup .modal-body .searchHolder {
  display: flex;
  justify-content: stretch;
  align-items: center;
}
#usersPopup .modal-body .searchHolder .homeColumnSearch {
  flex-grow: 2;
  margin-right: 10px;
}
#usersPopup .modal-body .listHolder {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}
#usersPopup .modal-body .listHolder #user {
  padding-right: 20px;
}
#usersPopup .modal-body .listHolder #user > a .details {
  width: 150px;
}
