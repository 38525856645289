#feed > header {
  text-align: right;
  color: #6f6f6f;
  position: relative;
  padding: 7px 9px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#feed > header .leftEnd {
  font-size: 0.9rem;
}
#feed > header .rightEnd {
  display: flex;
  align-items: center;
}
#feed > header:hover .reloadNewest {
  text-decoration: none;
}
#feed > header .reloadNewest {
  margin-right: 10px;
}
#feed > header .selectedHashtags {
  display: flex;
  justify-content: flex-end;
}
#feed > header .selectedHashtags .selectedHashtag {
  border-radius: 50px;
  padding: 3px 8px 4px;
  margin-right: 12px;
  background-color: #f32196;
  color: white;
  white-space: nowrap;
  display: flex;
  align-items: baseline;
  font-size: 0.9rem;
}
#feed > header .selectedHashtags .selectedHashtag i {
  margin-right: 3px;
  position: relative;
  top: 1px;
}
#feed > header #dropsFilterMenu {
  position: absolute;
  top: 22px;
  right: 0px;
  z-index: 1;
  text-align: left;
  background-color: #f8f8f8;
  padding: 10px 12px 5px;
}
#feed > header #dropsFilterMenu > div {
  margin-bottom: 5px;
}
#feed > header #dropsFilterMenu > div input {
  margin-right: 5px;
}
#feed > header .featuredDrop {
  float: left;
  color: #0c7cd5;
  margin-right: 30px;
}
#feed > header .featuredDrop i {
  margin-right: 2px;
}
#feed #drops {
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
}
#feed #drops .emptyList {
  text-align: center;
  font-weight: bold;
}
