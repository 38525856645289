.wikiOpener {
  position: relative;
  text-decoration: none !important;
}
.wikiOpener .fa-lightbulb {
  margin-right: 2px;
}
.wikiOpener .fa-lightbulb.cool {
  color: #eccc1b;
}
.wikiOpener .fire-gif {
  width: 35px;
  position: absolute;
  bottom: 15px;
  right: -5px;
  z-index: 100;
}
.wikiOpener .cool-data-gif {
  font-size: 22px;
  top: -1px;
  position: absolute;
  right: 0;
  color: #2196F3;
}
