@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";
@import (reference) "../../../../less/general";

.chargeForRoomEntrance {
	.flex;
	margin-top: 10px;
	input[type=number]{
		.border !important;
		.normalText;
		width: 42px;
		height: 20px;
		padding: 0 4px !important;
		margin: 0px !important
	}
	input {
		margin-right: 3px !important;
	}
	.flex;
	label {
		font-weight: normal !important;
		font-size: 1rem !important;
	}
	.beatsIcon{
		margin-right: 5px;
	}
}
