.threadHolder {
  position: relative;
  min-width: 200px;
  background-color: white;
  margin-bottom: 4px;
  padding: 6px 8px 8px;
  cursor: pointer;
}
.threadHolder.unread {
  background-color: #e6e6e6;
}
.threadHolder .upperRow {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.threadHolder .upperRow .userImage {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
}
.threadHolder .upperRow .userName {
  color: #f32196;
  margin-right: 2px;
  white-space: nowrap;
}
.threadHolder .upperRow .message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 10px;
  white-space: nowrap;
}
.threadHolder .upperRow .message img {
  height: 20px;
  width: auto;
}
.threadHolder .addedAtAndMarkAsRead {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  opacity: 0.7;
}
.threadHolder .addedAtAndMarkAsRead .btnMarkAsRead:hover {
  text-decoration: underline;
}
.threadHolder:hover .btnMarkAsRead {
  display: block;
}
