@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../less/variables';

#debugInfo {

  position: fixed;
  bottom: 0;
  left: 0;
  height: 80%;
  width: 30%;
  z-index: 1;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    background-color: #000;
    opacity: 0.9;
  }

  .content {
    padding: 10px;
    overflow-y: auto;
    color: #fff;

    .btnClose {
      position: absolute;
      top: 5px;
      right: 5px;
    }


    .item {
      .title {
        font-weight: bold;
        text-decoration: underline;
      }
      .label {
        font-weight: bold;
        font-size: 1em;
        color: gray;
      }
      ul {
        list-style-type: disc;
      }
    }
  }
}