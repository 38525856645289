#subscribableCasesList .subscribableCase {
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 14px 9px;
}
#subscribableCasesList .subscribableCase > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: default;
}
#subscribableCasesList .subscribableCase > div > div {
  text-align: left;
  display: flex;
  align-items: center;
}
#subscribableCasesList .subscribableCase > div > div img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
}
#subscribableCasesList .subscribableCase > div > div > div {
  display: flex;
  text-align: left;
  font-size: 1.2rem;
  flex-direction: column;
}
#subscribableCasesList .subscribableCase > div > div > div > div {
  display: flex;
  margin-bottom: 2px;
}
#subscribableCasesList .subscribableCase > div button {
  font-weight: normal;
  padding: 1px 11px 3px;
  height: 25px;
}
#subscribableCasesList .subscribableCase > div button.subscribed {
  background-color: rgba(0, 0, 0, 0.5);
}
#subscribableCasesList .subscribableCase > div button span {
  display: flex;
  align-items: center;
}
#subscribableCasesList .subscribableCase > div .stats {
  margin: 0 8px;
  font-weight: normal;
  font-size: 1rem;
}
#subscribableCasesList .subscribableCase > div b {
  text-overflow: ellipsis;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
#subscribableCasesList .subscribableCase > div .caseOwner {
  margin-left: 3px;
}
#subscribableCasesList .subscribableCase > div .caseOwner a {
  color: #0c7cd5;
}
#subscribableCasesList .subscribableCase > div .caseOwner a:hover {
  text-decoration: underline;
}
#subscribableCasesList .subscribableCase > div span {
  font-weight: normal;
  position: relative;
}
#subscribableCasesList .subscribableCase p {
  margin: 1px 0;
  color: #666666;
  font-weight: normal;
  font-size: 1rem !important;
}
