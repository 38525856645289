@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../../less/variables';
@import (reference) '../../../../../less/animations';

.chatMessageHoverOptions{
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 10px;
    height: 100%;
    align-items: center;
    background-color: @whiteBg_transparent;
    color: @color0;

    i {
        width: 100%;
        margin: 0 10px;
    }
}
