.leveledRoomBadge {
  color: white;
  font-size: 0.8rem;
  border-radius: 20px;
  display: inline;
  padding: 0px 8px 1px;
  margin-left: 5px;
  font-weight: normal;
  cursor: default;
  position: relative;
  bottom: 2px;
}
.leveledRoomBadge.roomLevel_2 {
  background-color: #eccc1b;
}
.leveledRoomBadge.roomLevel_3 {
  background-color: #a1944d;
}
.leveledRoomBadge.roomLevel_4 {
  background-color: black;
}
.leveledRoomBadge.disabled {
  background-color: gray;
  cursor: pointer;
}
