@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

.shareButton{
  display: inline-flex;
  flex-direction: row-reverse;

  .list {
    display: inline-flex;
    flex-direction: row-reverse;

    .option {
      margin-left: 5px;
    }
  }
}