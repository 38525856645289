@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/general";
@import (reference) "../../../../less/variables";

#paymentPopup{
	.modal-body {

		position: relative;

		.customButton {
			button{
				width: 100%;
				color: @textColor;
				background-repeat: no-repeat;
				background-position: left;
				background-size: 70px auto;
				background-color: white;
				font-weight: normal;
				.mediumText;
				position: relative;
				padding: 22px 0;
				border-radius: 0;
				&:hover{
					background-color: @bgColor_transparent;
				}
				.chevronHolder{
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
					.flex;
				}
			}
		}

		#googlePay {
			//button{
			//	background-image: url('../../../../images/google_pay.png');
			//}

			width: 292px;
			height: 48px;
			margin: 10px 0;
		}

		#paypalButton {
			border-top: 1px solid lightgrey;
			button{
				background-image: url('../../../../images/paypal.svg');
			}
		}

		#paymentRedirect {
			position: absolute;
			width: 100%;
			height: 150px;
			border-radius: 9px;
			left: 0;
			top: 0;
			color: #fff;
			font-weight: bold;
			.mediumText;
			.flex;
			background-color: @color2;
			i{
				margin-right: 5px;
			}
		}

		#mobileAppError {
			padding: 0 15px 20px;
			a {
				text-decoration: underline;
				color: blue;
			}
		}
	}
}