@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#myPrivilegedFeatureModal{
  .modal-body{
    font-size: 1.1rem;
    h2{
      font-weight: bold;
    }
    .transferMsg{
      color: blue;
    }
  }
  .buttonsHolder {
    .flex();
    margin: 22px 0 10px;
    > div{
      .smallText;
    }
    > button{
      margin: 0 10px;
      .fa-mug-hot{
        margin-left: 3px;
        position: relative;
        bottom: 1px;
      }
      &.glow-on-hover{
        background-color: @green4_dark;
      }
    }
  }
}