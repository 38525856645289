@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) '../../../../../less/variables';
@import (reference) '../../../../../less/animations';

.chatMessageInputReply{
    position: absolute;
    background-color: #4ba7dd;
    bottom: 0;
    width: 100%;
    color: white;
    padding: 3px 5px 5px;
    display: flex;

    .btnClose {
        position: absolute;
        top: 6px;
        right: 15px;
    }
    .userName{
        margin-right: 2px;
    }
    .text{
        .ellipsis;
        max-width: 200px;
        display: inline-block;
    }
}
