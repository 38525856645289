#userProfile {
  padding: 18px 20px;
  height: 100vh;
  position: relative;
  background-color: #f8f8f8;
}
#userProfile .userProfileHeader {
  display: flex;
  margin-bottom: 15px;
}
#userProfile .userProfileHeader article {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
#userProfile .userProfileHeader article .iconsHolder {
  position: absolute;
  top: 10px;
  right: 0px;
}
#userProfile .userProfileHeader article .iconsHolder > div > div {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
}
#userProfile .userProfileHeader article .iconsHolder > div > div .profileAction {
  color: white;
  margin-right: 7px;
  background-color: #2196F3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 0.9rem;
}
#userProfile .userProfileHeader article .iconsHolder > div > div .profileAction.disabled {
  background-color: #9b9898;
  cursor: default;
}
#userProfile .userProfileHeader article .iconsHolder > div > div .profileAction.fa-envelope {
  background-color: #f32196;
}
#userProfile .userProfileHeader article .iconsHolder > div > div .profileAction.donateBeats {
  margin-right: 7px;
  cursor: pointer;
}
#userProfile .userProfileHeader article .iconsHolder > div > div .profileAction.donateBeats .donationAmount {
  font-size: 0.9rem;
}
#userProfile .userProfileHeader article .iconsHolder > div > div .profileAction.donateBeats .fa-bolt {
  margin-left: 2px;
}
#userProfile .userProfileHeader article .iconsHolder > div > div .profileAction.donateBeats.admin {
  color: red;
}
#userProfile .userProfileHeader article .iconsHolder > div > div .profileAction.donateBeats.admin .fa-bolt {
  color: red;
}
#userProfile .userProfileBody {
  display: flex;
  flex-direction: column;
  padding-bottom: 0px !important;
}
#userProfile .userProfileBody > section,
#userProfile #musicTags {
  padding: 0 10px 10px;
  border-bottom: 10px;
  background-color: #f8f8f8;
  margin-bottom: 10px;
}
#userProfile #profileFeed {
  padding-bottom: 25px;
}
#userProfile #loadingProfile {
  text-align: center;
  width: 100%;
  color: #6f6f6f;
  margin-top: 16px;
  font-size: 50px;
}
#userProfile #userModalBanning {
  position: absolute;
  top: 52px;
}
#userProfile #userModalBanning button {
  background-color: red;
  padding: 0px 8px 4px;
  border-radius: 20px;
}
#userProfile #userData h3 {
  display: flex;
  font-size: 1.1rem;
  align-items: center;
}
#userProfile #userData h3 > * .countryIcon {
  margin-right: 5px;
  position: relative;
  bottom: -1px;
}
#userProfile #userData h3 .userName {
  font-weight: 100;
  color: #f32196;
  margin-right: 10px;
  font-size: 1.4rem;
}
#userProfile #userData h3 .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}
#userProfile #userData h3 .icons i {
  font-size: 0.9rem;
  margin-top: 4px;
  color: rgba(33, 150, 243, 0.5);
}
#userProfile #userData h3 .icons .adminData {
  color: black;
}
#userProfile #userData h3 .icons .userBanned {
  color: red;
}
#userProfile #userData .prevUserName {
  position: relative;
  top: -5px;
  color: gray;
  font-size: 0.9rem;
}
#userProfile #userData #follows {
  font-size: 0.9rem;
}
#userProfile #userData #follows > span {
  margin-right: 20px;
}
#userProfile #userData #follows > span b {
  font-weight: 100;
}
#userProfile #userData #rank {
  color: #0c7cd5;
  margin-top: 6px;
  font-size: 0.9rem;
}
#userProfile #userData #rank:hover {
  text-decoration: underline;
}
#userProfile #userData #rank a {
  text-decoration: none;
}
#userProfile #userData #rank a .smallText strong {
  text-decoration: underline;
}
#userProfile #userData #rank i.fa-star {
  color: #f32196;
  margin-right: 2px;
}
#userProfile .modalRooms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 24px;
}
#userProfile .modalRooms .modalRoom {
  display: flex;
  align-items: baseline;
}
#userProfile .modalRooms .modalRoom .roomName {
  color: #0c7cd5;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
#userProfile .modalRooms .modalRoom:hover {
  text-decoration: none;
}
#userProfile .modalRooms .modalRoom:hover .roomName {
  text-decoration: underline;
}
#userProfile .modalRooms .modalRoom .fa-lock {
  margin-right: 5px;
}
#userProfile .roomName {
  max-width: 366px;
  display: flex;
  align-items: center;
}
#userProfile .roomName i {
  color: #6f6f6f;
  margin-right: 4px;
  font-size: 0.8rem;
}
#userProfile .score {
  color: gray;
  font-size: 0.9rem;
  margin-left: 5px;
}
#userProfile .userProfile_label {
  margin: 15px 0 10px;
  width: 100%;
  font-weight: bold;
  color: #6f6f6f;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#userProfile .userProfile_label i {
  position: relative;
  top: 1px;
  margin-right: 5px;
}
#userProfile .userProfile_label a {
  text-decoration: underline;
}
#userProfile .userProfile_label strong {
  font-weight: 100;
}
#userProfile #feed header {
  display: none;
}
#userProfile #noDrops {
  width: 100%;
  text-align: center;
  color: gray;
}
#userProfile #onlinePresence #imOnline > i {
  color: #39e737;
  font-size: 0.9rem;
  position: relative;
  bottom: 1px;
}
#userProfile #onlinePresence .otherPlatform {
  margin-right: 4px;
  font-size: 1.2rem;
}
#userProfile #musicTags {
  background-color: #f8f8f8;
  padding: 8px;
  line-height: 22px;
}
#userProfile #musicTags #since {
  font-family: 'OleoScript', cursive;
  font-size: 1.2rem;
  color: #6f6f6f;
  text-align: center;
}
#userProfile #musicTags #tagsHolder {
  text-align: center;
  margin: 2px 0;
}
#userProfile #musicTags #tagsHolder .tag {
  white-space: nowrap;
  color: #f32196;
  display: inline-block;
}
#userProfile .social-link:hover {
  text-decoration: none;
}
#userProfile .user-image-holder {
  margin-right: 10px;
}
#userProfile .user-image-holder img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: 4px solid #f8f8f8;
}
#userProfile #featuredSongs {
  text-align: center;
  padding: 2px 6px 3px 2px;
}
#userProfile #featuredSongs p .fa-question-circle {
  bottom: 1px;
  font-size: 0.9rem;
}
#userProfile #featuredSongs p a {
  color: #0c7cd5;
  text-decoration: underline;
  cursor: pointer;
  font-weight: normal;
}
#userProfile #featuredSongs .zebraList {
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
#userProfile #featuredSongs .featuredSongOpened {
  opacity: 1;
  height: auto;
}
#userProfile #userSoundtracks label {
  margin-bottom: 15px;
}
#userProfile #userSoundtracks .soundtrack {
  padding: 2px 6px 3px 2px;
  display: flex;
  align-items: center;
}
#userProfile #userSoundtracks .soundtrack .roomName {
  margin-right: 3px;
}
#userProfile #userSoundtracks .soundtrack i {
  line-height: 20px;
  font-size: 0.8rem;
}
#userProfile #userSoundtracks .soundtrack img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 3px;
}
#userProfile #madeMyDaySong {
  text-align: center;
}
#userProfile #madeMyDaySong a {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  white-space: pre-wrap;
  font-size: 1.1rem;
  background-color: #fff7cc;
  padding: 8px 20px;
  border-radius: 20px;
}
#userProfile #madeMyDaySong a img {
  border-radius: 20px;
  margin-right: 15px;
}
#userProfile #adminSection {
  position: absolute;
  z-index: 1000;
  left: 20px;
  top: 108px;
}
#userProfile #adminSection .userData {
  background-color: lightgrey;
  padding: 3px 20px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
#userProfile .userNotFound {
  text-align: center;
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: bold;
}
.inRoom .userProfileBody > section,
.inRoom #musicTags {
  background-color: white !important;
}
