#create-room-modal #roomManagementHolder.themesDisplayed {
  width: 582px;
}
#create-room-modal #roomManagementHolder #roomThemesManagement {
  padding: 0 12px;
}
#create-room-modal .modal-body {
  width: 500px;
  padding: 0px;
  padding-bottom: 14px;
}
#create-room-modal .modal-body .subTitle {
  margin: 25px 0;
  text-align: center;
  padding: 0 30px;
}
#create-room-modal .modal-body #createRoomForm {
  margin-top: -6px;
  margin-bottom: 0px;
}
#create-room-modal .modal-body #createRoomForm section {
  margin-bottom: 22px;
  padding: 0 12px;
}
#create-room-modal .modal-body #createRoomForm .inputArea {
  display: flex;
  white-space: nowrap;
  margin-bottom: 5px;
}
#create-room-modal .modal-body #createRoomForm .inputArea #projectUrl {
  color: gray;
  font-weight: normal;
}
#create-room-modal .modal-body #createRoomForm tags-input[disabled] .tags,
#create-room-modal .modal-body #createRoomForm tags-input[disabled] .tags .input {
  background-color: #f8f8f8;
}
#create-room-modal .modal-body #createRoomForm input {
  border: none;
  border-bottom: 1px solid #D2D2D2;
  padding: 0px;
  background-color: inherit;
  margin: 0 0 0 5px;
  height: 24px;
  position: relative;
  top: 1px;
}
#create-room-modal .modal-body #createRoomForm input[type=checkbox] {
  margin: 0;
}
#create-room-modal .modal-body #createRoomForm label {
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#create-room-modal .modal-body #createRoomForm .btn {
  padding: 8px 22px 9px;
}
#create-room-modal .modal-body #createRoomForm input[type="checkbox"],
#create-room-modal .modal-body #createRoomForm input[type="radio"] {
  margin-right: 4px;
}
#create-room-modal .modal-body #createRoomForm tags-input .host {
  margin: 0;
}
#create-room-modal .modal-body #createRoomForm tags-input .tags {
  box-shadow: none;
  border: none;
}
#create-room-modal .modal-body #createRoomForm tags-input .tags input {
  color: #4a4a4a;
  width: 353px !important;
}
#create-room-modal .modal-body #createRoomForm #roomTags .field-error {
  margin-top: 5px;
  font-weight: bold;
  padding-bottom: 0px;
}
#create-room-modal .modal-body #createRoomForm #roomTags .autocomplete {
  margin-top: 0px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0px;
}
#create-room-modal .modal-body #createRoomForm #roomTags .autocomplete .suggestion-list {
  max-height: 168px;
  overflow-y: scroll;
}
#create-room-modal .modal-body #createRoomForm #roomTags .autocomplete .suggestion-item.selected {
  background-color: #eaeaea;
  color: black;
}
#create-room-modal .modal-body #createRoomForm #roomTags .autocomplete .suggestion-item.selected em {
  background-color: transparent;
  color: black;
}
#create-room-modal .modal-body #createRoomForm #roomTags #everything {
  display: flex;
  align-items: center;
  color: gray;
}
#create-room-modal .modal-body #createRoomForm #roomTags #everything .chkAllTagsLabel {
  font-weight: normal;
  font-size: 1rem;
  position: relative;
  top: 1px;
}
#create-room-modal .modal-body #createRoomForm #roomTags #everything .mixtape {
  color: #404040;
}
#create-room-modal .modal-body #createRoomForm #roomTags .tagsSpinner {
  position: absolute;
  top: 43px;
  right: 10px;
}
#create-room-modal .modal-body #createRoomForm .outlineSection {
  border: 4px solid #2196F3;
  padding: 3px 7px;
}
#create-room-modal .modal-body #createRoomForm #autoPlaySettings label {
  display: flex;
  align-items: center;
}
#create-room-modal .modal-body #createRoomForm #autoPlaySettings label .fa-question-circle {
  margin-left: 4px;
  top: 1px;
  position: relative;
  font-size: 1rem;
}
#create-room-modal .modal-body #createRoomForm #autoPlaySettings #autoPlaySettings_radios {
  display: flex;
  margin-top: 7px;
}
#create-room-modal .modal-body #createRoomForm #autoPlaySettings #autoPlaySettings_radios.disabledArea {
  opacity: 0.5;
}
#create-room-modal .modal-body #createRoomForm #autoPlaySettings #autoPlaySettings_radios > div {
  display: inline-flex;
  align-items: center;
}
#create-room-modal .modal-body #createRoomForm #autoPlaySettings #autoPlaySettings_radios > div > label {
  margin-right: 10px;
  position: relative;
  top: 2px;
  font-size: 0.9rem;
  color: #6f6f6f;
  font-weight: normal;
  white-space: nowrap;
}
#create-room-modal .modal-body #createRoomForm #roomDescriptionToggler {
  flex-direction: column;
}
#create-room-modal .modal-body #createRoomForm #roomDescriptionToggler #descriptionArea {
  margin-top: 5px;
}
#create-room-modal .modal-body #createRoomForm #roomDescriptionToggler #descriptionArea #txtDescription {
  width: 100%;
  height: 60px;
  padding: 3px 5px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  font-size: 0.95rem;
}
#create-room-modal .modal-body #createRoomForm #btnShowDescription,
#create-room-modal .modal-body #createRoomForm #btnSelectATheme {
  display: flex;
  align-items: center;
}
#create-room-modal .modal-body #createRoomForm #btnShowDescription i,
#create-room-modal .modal-body #createRoomForm #btnSelectATheme i {
  margin-right: 5px;
  position: relative;
  top: 1px;
}
#create-room-modal .modal-body #createRoomForm #themeOptions {
  display: flex;
  align-items: center;
}
#create-room-modal .modal-body #createRoomForm #themeOptions a {
  margin-right: 10px;
}
#create-room-modal .modal-body #createRoomForm #themeOptions a i {
  margin-right: 3px;
}
#create-room-modal .modal-body #createRoomForm #themeOptions img,
#create-room-modal .modal-body #createRoomForm #themeOptions video {
  width: 50px;
}
#create-room-modal .modal-body #createRoomForm #blockOthersSongsSwitch {
  display: flex;
  justify-content: space-between;
}
#create-room-modal .modal-body #createRoomForm #roomType {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#create-room-modal .modal-body #createRoomForm #roomType #roomTypesRadios {
  display: flex;
  width: 100%;
  align-items: baseline;
}
#create-room-modal .modal-body #createRoomForm #roomType #roomTypesRadios > div {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#create-room-modal .modal-body #createRoomForm #roomType #roomTypesRadios label {
  margin-right: 8px;
  font-size: 0.9rem;
  position: relative;
  top: 1px;
}
#create-room-modal .modal-body #createRoomForm #roomType #roomTypesRadios label span {
  margin-right: 5px;
}
#create-room-modal .modal-body #createRoomForm #roomType #roomTypesRadios label i:not(.fa-bolt) {
  color: #2196F3;
  margin-right: 5px;
}
#create-room-modal .modal-body #createRoomForm #roomType #roomTypesRadios label span {
  margin-right: 2px;
}
#create-room-modal .modal-body #createRoomForm #createEditRoomBtnWrapper {
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: center;
}
#create-room-modal .modal-body #createRoomForm #createEditRoomBtnWrapper #beatsBalance {
  font-size: 0.9rem;
  margin-top: 10px;
}
#create-room-modal .modal-body #createRoomForm #createEditRoomBtnWrapper #beatsBalance b {
  font-weight: 100;
}
#create-room-modal .modal-body #createRoomForm #create-room_room-type {
  margin-top: 14px !important;
}
#create-room-modal .modal-body #createRoomForm #create-room_room-type label {
  font-weight: inherit;
  margin-top: 0px;
}
#create-room-modal .modal-body #createRoomForm #create-room_room-type input[type=radio] {
  position: relative;
  top: 1px;
}
