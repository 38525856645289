.chatMessageRow {
  padding: 3px 9px 7px 9px;
  position: relative;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}
.chatMessageRow.rtl {
  direction: rtl;
}
.chatMessageRow.rtl .sendIndicator {
  left: 6px;
  right: auto;
}
.chatMessageRow.rtl .messageColor {
  margin-right: 14px;
}
.chatMessageRow .messageColor {
  min-width: 4px;
  height: 100%;
  position: absolute;
  left: 2px;
  top: 0;
}
.chatMessageRow .userName a {
  color: #97daf7;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80px;
  display: inline-block;
  line-height: 16px;
  top: 3px;
  position: relative;
}
.chatMessageRow .separator {
  position: relative;
  right: 2px;
}
.chatMessageRow .msgTxt a:not(.user-link-inline) {
  color: #00aced;
}
.chatMessageRow .msgTxt a:not(.user-link-inline):hover {
  text-decoration: underline;
}
.chatMessageRow .msgTxt .user-link-inline {
  color: #f32196;
}
.chatMessageRow img {
  max-height: 110px;
}
.chatMessageRow .sendIndicator {
  position: absolute;
  bottom: 1px;
  right: 16px;
}
.chatMessageRow .sendIndicator > i {
  font-size: 0.7em;
}
.chatMessageRow .sendIndicator.read {
  color: yellow;
}
.chatMessageRow:hover .chatMessageHoverOptions {
  display: flex;
}
.chatMessageColorTooltip {
  max-width: 160px;
}
