@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/general";
@import (reference) "../../../../less/variables";

#approveTosPopup{
	text-align: center;
	a{
		color: @color0_dark;
		text-decoration: underline;
	}
}