.shareButton {
  display: inline-flex;
  flex-direction: row-reverse;
}
.shareButton .list {
  display: inline-flex;
  flex-direction: row-reverse;
}
.shareButton .list .option {
  margin-left: 5px;
}
