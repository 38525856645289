@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#profileWatchesPopup {
    text-align: center;
    .modal-body{
        padding: 0px 10px 20px;
        h3{
            font-weight: normal;
            b{
                font-weight: 100;
            }
        }
        h4{
            .mediumText;
            font-weight: normal;
            padding: 12px;
        }
        button{
            .mediumText;
        }
    }
   .watchersList {
       li{
           padding: 8px 6px 10px;
           .mediumText;
           .userNameAndDate{
               display: flex;
               justify-content: space-between;
               width: 100%;
               position: relative;
               bottom: 2px;
               .userName{
                   color: @color0_dark;
               }
               .watchDate{
                   color: gray;
                   .smallText;
               }
           }
           .userImageHolder {
               img {
                   width: 35px;
                   height: 35px;
                   border-radius: 50%;
                   margin-right: 8px;
               }
           }
       }
   }
}
