#chats .chat {
  pointer-events: auto;
  margin-right: 10px;
  width: 320px;
}
#chats .chat .chatHeader {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 3px 2px 5px 5px;
  width: 320px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px -4px;
}
#chats .chat .chatHeader > * {
  display: flex;
  align-items: center;
}
#chats .chat .chatHeader > * > * {
  display: flex;
  align-items: center;
  margin-right: 13px;
}
#chats .chat .chatHeader.focus {
  background-color: #efefef;
}
#chats .chat .chatHeader .userImage {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
#chats .chat .chatHeader .userName {
  margin-left: 5px;
  display: inherit;
}
#chats .chat .chatHeader .unreads {
  background-color: #53bd87;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding-left: 1px;
  position: relative;
  top: 1px;
}
#chats .chat .chatHeader .btnClose {
  cursor: pointer;
}
#chats .chat .chatHeader .fa-shield-alt {
  opacity: 0.4;
}
#chats .chat .chatHeader .blocked {
  color: #FF8181;
  font-size: 1.2rem;
  opacity: 1;
}
#chats .chat .chatBody {
  height: 256px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}
#chats .chat .chatBody .messagesHolder {
  height: 225px;
  position: relative;
}
#chats .chat .chatBody .messagesHolder .scrollArea {
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 225px;
  width: 100%;
}
#chats .chat .chatBody .messagesHolder .scrollArea .chatMessageRow {
  background-color: rgba(0, 0, 0, 0.9);
}
#chats .chat .chatBody .messagesHolder .scrollArea :nth-child(even) .chatMessageRow {
  background-color: rgba(0, 0, 0, 0.8);
}
#chats .chat .chatBody .loader {
  width: 100%;
  height: 100%;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  text-align: center;
}
#chats .chat .chatBody .loader > i {
  font-size: 6em;
  margin-top: 73px;
  color: #fff;
}
#chats .chat.mobile {
  width: 100vw;
  font-size: 16px;
  margin-right: 0px;
}
#chats .chat.mobile .chatBody,
#chats .chat.mobile .chatBody .messagesHolder {
  height: auto !important;
}
#chats .chat.mobile .chatBody {
  background-color: #04527e;
}
#chats .chat.mobile .chatBody .messagesHolder .scrollArea {
  height: 45vh !important;
  max-height: initial !important;
  width: 100%;
  overflow-y: auto !important;
  position: relative;
  bottom: auto;
  left: auto;
}
#chats .chat.mobile .chatInputHolder input {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid lightgrey;
}
#chats .chat.mobile .chatInputHolder .chatEmojisOpener {
  right: 45px;
  font-size: 18px;
  top: 3px;
}
#chats .chat.mobile .chatInputHolder .gifsOpener {
  right: 8px;
  font-size: 18px;
  padding: 4px;
  top: 3px;
}
#chats .chat.mobile .chatHeader {
  width: inherit;
}
