@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../../less/general";
@import (reference) "../../../../../less/variables";

.threadHolder {
    position: relative;
    min-width: 200px;
    background-color: white;
    margin-bottom: 4px;
    padding: 6px 8px 8px;
    cursor: pointer;
    .clickable;

    &.unread {
        background-color: @bgColor;
    }

    .upperRow{
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        .userImage{
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 5px;
        }
        .userName{
            color: @color2;
            margin-right: 2px;
            white-space: nowrap;
        }
        .message {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            white-space: nowrap;
            padding-right: 10px;
            white-space: nowrap;
            img{
                height: 20px;
                width: auto;
            }
        }
    }
    .addedAtAndMarkAsRead{
        display: flex;
        justify-content: space-between;
        .tinyText;
        opacity: 0.7;
        .btnMarkAsRead:hover{
            text-decoration: underline;
        }
    }

    &:hover {
        .btnMarkAsRead {
            display: block;
        }
    }
}