#paymentPopup .modal-body {
  position: relative;
}
#paymentPopup .modal-body .customButton button {
  width: 100%;
  color: #404040;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 70px auto;
  background-color: white;
  font-weight: normal;
  font-size: 1.2rem;
  position: relative;
  padding: 22px 0;
  border-radius: 0;
}
#paymentPopup .modal-body .customButton button:hover {
  background-color: rgba(238, 238, 238, 0.8);
}
#paymentPopup .modal-body .customButton button .chevronHolder {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
#paymentPopup .modal-body #googlePay {
  width: 292px;
  height: 48px;
  margin: 10px 0;
}
#paymentPopup .modal-body #paypalButton {
  border-top: 1px solid lightgrey;
}
#paymentPopup .modal-body #paypalButton button {
  background-image: url('../../../../images/paypal.svg');
}
#paymentPopup .modal-body #paymentRedirect {
  position: absolute;
  width: 100%;
  height: 150px;
  border-radius: 9px;
  left: 0;
  top: 0;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background-color: #f32196;
}
#paymentPopup .modal-body #paymentRedirect i {
  margin-right: 5px;
}
#paymentPopup .modal-body #mobileAppError {
  padding: 0 15px 20px;
}
#paymentPopup .modal-body #mobileAppError a {
  text-decoration: underline;
  color: blue;
}
