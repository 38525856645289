#songModal .modal-body .songNotFoundError {
  color: red;
  text-align: center;
}
#songModal .modal-body .songNotFoundError button {
  margin-top: 10px;
}
#songModal .spinnerHolder {
  text-align: center;
  padding-top: 132px;
}
#songModal .spinnerHolder #mySpinner {
  font-size: 5em;
}
