.chatMessageHoverOptions {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  height: 100%;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: #2196F3;
}
.chatMessageHoverOptions i {
  width: 100%;
  margin: 0 10px;
}
