.songsCarousel.scroller {
  height: 91px;
  position: relative;
  overflow: hidden;
}
.songsCarousel.scroller .scrollButton {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 35px;
  width: 35px;
  font-size: 20px;
  top: 19px;
  color: #0c7cd5;
  cursor: pointer;
}
.songsCarousel.scroller .scrollButton.prev {
  left: 0;
  box-shadow: 5px 0px 1px #f32196;
  text-shadow: -4px 0px 1px #f32196;
}
.songsCarousel.scroller .scrollButton.next {
  right: 0px;
  box-shadow: -5px 0px 1px #f32196;
  text-shadow: 4px 0px 1px #f32196;
}
.songsCarousel.scroller .results {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}
.songsCarousel.scroller .results.centered {
  display: flex;
  justify-content: center;
  width: 100%;
}
.songsCarousel.scroller .results > .result {
  display: inline-block;
  margin-right: 8px;
  width: 125px;
  position: relative;
}
.songsCarousel.scroller .results > .result .song-thumb-wraper {
  height: 75px;
  overflow: hidden;
  border-radius: 20px;
}
.songsCarousel.scroller .results > .result .song-thumb-wraper img.song-thumb {
  position: relative;
  top: -12px;
  width: 130px;
}
.songsCarousel.scroller .results > .result .duration {
  position: absolute;
  right: 0;
  top: 0px;
  padding: 0 9px;
  border-radius: 0 20px 0 20px;
  line-height: 17px;
  font-size: 0.8rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
}
.songsCarousel.scroller .results > .result .name {
  font-size: 0.8rem;
  text-align: left;
  bottom: -19px;
  position: absolute;
  width: 100%;
  line-height: 14px;
  padding-bottom: 2px;
}
.songsCarousel.scroller .results > .result a:hover {
  text-decoration: none;
}
.songsCarousel.scroller .results .result:last-of-type {
  margin-right: 0;
}
