#redirectToPlatformPopup {
  text-align: center;
  font-size: 1.2rem;
}
#redirectToPlatformPopup p {
  font-size: 1rem;
  margin-top: 15px;
  color: gray;
}
#redirectToPlatformPopup button {
  margin-top: 18px;
}
#redirectToPlatformPopup button a:hover {
  text-decoration: none;
}
