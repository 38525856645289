@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";

#myGifsModal{
    .modal-body {
        padding-top: 20px;
        height: 592px;
        width: 620px;
        > .fa-times{
            float: right;
            font-size: 1.2rem;
        }
        .gifsModalSearchBox {
            width: 100%;
            margin: 12px 0;

            > input, > i {
                .mediumText;
            }

            > input {
                height: 40px;
                width: 100%;
                max-width: initial;
            }
        }

        .listHolder {

            display: flex;
            flex-flow: row wrap;

            max-height: 462px;
            overflow-y: auto;

            .gifItem {
                width: 116px;
                height: 116px;
                position: relative;
                cursor: pointer;

                img {
                    height: 100%;
                    width: 100%;
                }

                .infoHolder{
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    color: #fff;


                    .bg, .price, .user {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .bg {
                        background-color: #000;
                        opacity: 0.5;
                    }

                    .price {
                        font-weight: bold;
                        .flex;
                        .txt{
                            .bigText;
                        }
                        .amount{
                            margin-left:8px;
                            position: relative;
                            top: 1px;
                            .mediumText;
                        }
                    }

                    .user {
                        top: auto;
                        bottom: 0;
                        color: #fff;
                        height: auto;
                        padding: 5px;
                        .smallerText;
                    }
                }

                &:hover {
                    .infoHolder {
                        display: block;
                    }
                }
            }
        }

        .poweredByHolder{
            margin-top: 5px;
            font-size: 0.75em;
            font-weight: bold;

            > img{
                width: 60px;
            }
        }
    }
}